/* You can add global styles to this file, and also import other style files */
@import "@angular/material/prebuilt-themes/indigo-pink.css";
@import "./queries";
@import "./variables";
@import "./mixins";
@import "./icons";
@import "./typography";
// @import "./buttons";
@import "./mod-text";
@import "./a-text";
@import "./lay-wrapper";
@import "./mod-navigation";
@import "./mod-footer";
@import "./mod-logo";
@import "./mod-header";
@import "node_modules/angular-notifier/styles"; // see https://stackoverflow.com/questions/71383279/i-cant-import-the-styles-scss-file-from-angular-notifier-into-my-project

@font-face {
  font-family: "Roboto";
  font-style: italic;
  font-weight: 300;
  font-display: swap;
  src: url("/assets/fonts/Roboto/Roboto-LightItalic.ttf") format("truetype");
}
@font-face {
  font-family: "Roboto";
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url("/assets/fonts/Roboto/Roboto-Italic.ttf") format("truetype");
}
@font-face {
  font-family: "Roboto";
  font-style: italic;
  font-weight: 500;
  font-display: swap;
  src: url("/assets/fonts/Roboto/Roboto-MediumItalic.ttf") format("truetype");
}
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url("/assets/fonts/Roboto/Roboto-Light.ttf") format("truetype");
}
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("/assets/fonts/Roboto/Roboto-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url("/assets/fonts/Roboto/Roboto-Medium.ttf") format("truetype");
}

@font-face {
  font-family: "Material Icons";
  font-style: normal;
  font-weight: 400;
  src: url("/assets/fonts/MaterialIcons/material-icons.woff2") format("woff2");
}

.material-icons {
  font-family: "Material Icons";
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-feature-settings: "liga";
  -webkit-font-smoothing: antialiased;
}

@font-face {
  font-family: "Material Symbols Outlined";
  font-style: normal;
  font-weight: 400;
  src: url("/assets/fonts/MaterialIcons/material-symbols-outlined.woff2") format("woff2");
}

.material-icons-outlined {
  font-family: "Material Symbols Outlined";
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-feature-settings: "liga";
  -webkit-font-smoothing: antialiased;
}

body {
  --mat-form-field-color: #4c4c68;
  --mat-focused-label-color: #000028;
  --mat-ripple-color: #00b3b3;
  background: #F3F3F0;
  .lay-wrapper {
    background: #F3F3F0;
  }
  &.print-body {
    background: white !important;
    .lay-wrapper {
      background: white !important;
    }
  }
}

.notifier__notification-message {
  font-family: Siemens Sans bold;
  color: white;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-transition: "color 9999s ease-out, background-color 9999s ease-out";
  transition: "color 9999s ease-out, background-color 9999s ease-out";
  -webkit-transition-delay: 9999s;
  transition-delay: 9999s;
  font-family: Siemens Sans;
}

.mat-form-field-appearance-fill {
  width: 100%;

  .mat-date-range-input input {
    &:first-child {
      top: 2px;
    }
    color: var(--text-color);
    font-size: 90%;
    // line-height: 28px;
    font-family: "Siemens Sans";
  }

  .mat-input-element {
    color: var(--text-color);
    font-size: 90%;
    // line-height: 28px;
    font-family: "Siemens Sans";
  }
  .mat-input-element::placeholder {
    color: var(--placeholder-color);
  }
  .mat-select-arrow {
    color: var(--mat-form-field-color);
  }
  .mat-form-field-underline::before {
    background-color: var(--mat-form-field-color);
  }
  .mat-form-field-flex {
    background-color: rgba(0, 204, 204, 0.08);
    height: 54px;
    border-radius: 0px;
  }

  &.gmm-text-area {
    .mat-form-field-flex {
      height: auto;
      min-height: 54px;
    }
  }
  .mat-form-field-label {
    color: var(--mat-form-field-color);
    display: flex !important;
    flex-direction: row-reverse;
    justify-content: flex-end;
    font-family: "Siemens Sans";
  }

  &.mat-focused {
    .mat-form-field-ripple {
      background-color: var(--mat-ripple-color);
    }
    .mat-form-field-flex {
      background-color: rgba(0, 204, 204, 0.2);
    }
    .mat-form-field-label {
      color: var(--mat-focused-label-color);
    }
    &.mat-primary {
      .mat-select-arrow {
        color: var(--mat-form-field-color);
      }
    }
  }

  &.mat-form-field-disabled {
    .mat-form-field-flex {
      // background-color: rgba(0, 204, 204, 0.08);
      background-color: rgba(204, 204, 204, 0.08);
    }
    .mat-form-field-underline::before {
      background-color: transparent;
    }
  }
  &.mat-form-field-invalid {
    .mat-form-field-flex {
      background-color: rgba(239, 1, 55, 0.2);
    }
    .mat-form-field-ripple {
      background-color: #ef0137;
    }
    .mat-form-field-label {
      color: #ef0137;
    }
  }

  .mat-select-value-text span {
    font-size: 18px;
    color: var(--text-color);
  }
}

.mat-text-area-field {
  .mat-form-field-flex {
    height: auto;
  }
}



a {
  color: #009999;
  font-family: Siemens Sans bold;
}

.mat-checkbox-inner-container {
  height: 25px !important;
  width: 25px !important;
}

.mat-checkbox-ripple .mat-ripple-element,
.mat-checkbox-checked.mat-accent .mat-checkbox-background {
  background: #686868 !important;
}
.mat-option {
  .mat-pseudo-checkbox-checked {
    background: #03819C !important;
  }
}

.mat-dialog-container {
  background: #F3F3F0;
}

.mat-select {
  min-width: 100px;
  width: calc(100% - 22px) !important;
  padding: 10px;
  height: 20px;
  border: 1px solid lightgray;
  background: white;
}

.bold {
  font-weight: bold;
}

.mat-tab-disabled p {
  color: lightgray;
}

button {
  color: #fff;
  background-color: #00CCCC;
  border-color: #00CCCC;
  border-style: solid;
  // border-radius: 100px;
  border-width: 1px;
  cursor: pointer;
  display: inline-block;
  font-family: Siemens sans;
  font-size: 16.001px;
  font-size: 0.889rem;
  font-weight: 600;
  // line-height: 1;
  margin: 0 0 1.25em;
  min-width: 111.999px;
  min-width: 6.222222rem;
  padding: 10.999px 23.999px;
  padding: 0.611111rem 1.333333rem;
  position: relative;
  text-decoration: none;
  text-align: center;
  -webkit-transition: all 0.16s linear;
  transition: all 0.16s linear;
  -webkit-appearance: none;
  padding-left: 30px;
  padding-top: 12px;
  line-height: 16px;
  transition: all 300ms ease-in-out;
  &:hover{
    background-color: #03819C;
    border-color: #03819C;
  }
  i {
    position: absolute;
    left: 4px;
    top: 50%;
    transform: translate(0px, -50%);
  }
  &.btn-purple {
    color: #03819C;
    background-color: transparent;
    border-color: #03819C;
    &:hover {
      background-color: #03819C;
      border-color: #03819C;
      color: #F3F3F0;
    }
  }
  &.btn-red {
    color: #fff;
    background-color: #02646D;
    border-color: #02646D;
    &:hover {
      background-color: #005566;
    }
  }

  &:disabled {
    opacity: 0.4;
  }
  &:focus {
    outline: none;
  }
}

.mat-select-value-text span {
  font-family: Siemens Sans !important;
  font-size: 90%;
}

// button {
//   font-family: Siemens Sans Bold !important;
//   font-style: normal;
//   font-size: 16px !important;
//   line-height: 18px !important;
//   /* identical to box height */
//   display: flex;
//   // align-items: center !important;
//   text-align: left !important;
//   letter-spacing: 0.55px;
//   padding: 16px !important;
//   /* Snow */
//   min-width: 230px !important;
//   // text-transform: uppercase;
//   border-radius: 0px !important;
//   transition: transform 300ms ease-in-out;
//   background: #ED6600;
//   cursor: pointer;
//   color: white;
//   border: 0;
// }

li::marker {
  font-family: Siemens Sans;
  font-size: 14px;
}

.check-list {
  list-style: none;
  li {
    position: relative;
  }
  li:before {
    content: "✓";
    position: absolute;
    left: -20px;
  }
}

.topTexts {
  p {
    // text-align: left;
  }
}
.introbulletpoints {
  ul {
    width: -moz-fit-content;
    width: fit-content;
    p {
      text-align: left;
      margin-top: 6px;
      margin-bottom: 6px;
    }
  }
}

.dialog-active.cdk-global-scrollblock {
  position: initial !important;
}

.dialog-active.cdk-global-scrollblock body {
  position: fixed;
}

.mat-placeholder-required {
  color: crimson;
}

.invisible-tabs {
  > .mat-tab-header {
    display: none !important;
  }
}

b {
  font-family: Siemens Sans bold;
}

.mat-form-field-suffix {
  color: darkslategrey
}

.special-existing-select {
  .mat-select {
    padding-top: 0px;
    background: none;
    border: none;

    .mat-select-value-text span {
      font-size: 16px;
    }
  }
}

.pagination-select {
  .mat-select {
    border: none;
    min-width: inherit;
    width: inherit;
    height: inherit;
  }
}

.normal-input {
  width: 100%;
  border: 0px;
  // margin-bottom: -70px;
  height: 25px;
  display: block;
  padding: 5px 0px;
  padding-bottom: 2px;
  border: 1px solid lightgray;
  font-family: Siemens Sans;
}

.print-start {
  max-height: 100vh;
  min-height: 100vh;
  max-width: 100vw;
  min-width: 100vw;
}

// button:hover {
//   color: #fff;
//   background-color: #565656;
//   border-color: #565656;
//   outline: 0;

//   &.btn-purple {
//     color: #fff;
//     background-color: #5f009e;
//     border-color: #5f009e;
//   }
//   &.btn-red {
//     color: #fff;
//     background-color: #492f65;
//     border-color: #492f65;
//     &:hover {
//       background-color: #331f48;
//     }
//   }
// }

shig-home {
  .one {
    .mat-ink-bar {
      background-color: #009999 !important;
    }
  }
  .two {
    .mat-ink-bar {
      background-color: #4f4a62 !important;
    }
  }
  .three {
    .mat-ink-bar {
      background-color: #25abab !important;
    }
  }
}
shig-home {
  .checkIcon-one {
    .mat-checkbox-ripple .mat-ripple-element,
    .mat-checkbox-checked.mat-accent .mat-checkbox-background {
      background: #009999 !important;
    }
  }
  .checkIcon-two {
    .mat-checkbox-ripple .mat-ripple-element,
    .mat-checkbox-checked.mat-accent .mat-checkbox-background {
      background: #4f4a62 !important;
    }
  }
  .checkIcon-three {
    .mat-checkbox-ripple .mat-ripple-element,
    .mat-checkbox-checked.mat-accent .mat-checkbox-background {
      background: #25abab !important;
    }
  }
}
.checkdrop-one {
  .mat-pseudo-checkbox-checked {
    background: #009999 !important;
  }
}
.checkdrop-two {
  .mat-pseudo-checkbox-checked {
    background: #4f4a62 !important;
  }
}
.checkdrop-three {
  .mat-pseudo-checkbox-checked {
    background: #25abab !important;
  }
}

.ig-tooltip {
  font-family: Siemens Sans;
  font-size: 14px;
  // text-align: center;
  white-space: pre-line;

  &--large-left {
    margin-left: 93px !important;
  }
}


.new-tab-tooltip {
  background: rgba(47,79,79, 0.9);
  margin: 10px 14px !important;
  position: relative;
  bottom: 3px;
}

.header-img {
  width: 100%;
}

.check-data__panel {
  white-space: pre-wrap;
  overflow-wrap: break-word;
}

