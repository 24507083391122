.lay-wrapper {
  margin: 0 auto;
  width: 100%;
  @include clearfix();
  position: relative;
  transition: transform 0.5s ease-in-out;

  > * {
      &:last-child {
          @include mq(print) {
              border-bottom: none !important;
          }
      }
  }

  &:last-of-type {
      margin-bottom: 0;
  }

  &--header {
      top: 0;
      height: $header-height;
      background: $white;
      border-bottom: 2px solid $nav-bottom-line;
      position: fixed;
      z-index: 12;
      min-width: 320px;

      @include mq(print) {
          display: none;
      }
  }

  &--main {
    padding-bottom: 100px;
    display: block;
  }

  &--footer {
      left: 0;
      right: 0;
      bottom: 0;
      padding-top: 60px;
      opacity: 1;
      position: absolute;

      @include mq(print) {
          display: none;
      }

      &.dark {
          background: $bg-grey;
      }

      &.lightfont {

          span,
          a {
              color: $white !important;
          }
      }

    &.show {
      opacity: 1;
      transition: opacity 0.3s ease-in-out;
    }
  }

  &--default {
      width: 92.5%;
      margin: 0 auto;
      max-width: $max-width-s;
      position: relative;

      @include mq(desktop-m) {
          max-width: $max-width-m;
      }

      @include mq(desktop-l) {
          max-width: $max-width-l;
      }

      @include mq(desktop-xl) {
          max-width: $max-width-xl;
      }
  }

  &--full-width {
      width: 100%;
      position: relative;
      @include clearfix();
  }

  &--top-distance {
      padding-top: 130px;

      @include mq(print) {
          padding-top: 0;
      }

      &-small {
          padding-top: $header-height;

          @include mq(print) {
              padding-top: 0;
          }
      }
  }

  &--bottom-distance {
      margin-bottom: 100px;

      &-small {
          margin-bottom: 50px;
      }
  }


  // headroom styles
  &.headroom {
      transition: top 200ms linear;
  }

  &.headroom--pinned {
      top: 0;
  }

  &.headroom--unpinned {
      top: -$header-height;
  }


  // refactoring - add layout wrapper
  &--sa {
      padding: 140px 0 70px 0;
      background: $tile-color-sa;

      @include mq(print) {
          @include print-clean();
      }
  }
}
