// text atoms

h1,
.a-text-headline-page {
  @include headline-page($font-color);
}

h2,
.a-text-headline-chapter {
  @include headline-chapter($font-color);
}

h3,
.a-text-headline-component {
  @include headline-component($font-color);
}

h4,
.a-text-headline-txt {
  @include headline-txt($font-color);
}

p,
//span,
.a-text-copy-txt {
  @include copy-txt($font-color);
}

.a-text-copy-txt-bold {
  @include copy-txt-bold($font-color);
}

.a-text-copy-txt-chapter-title {
  @include copy-txt-chapter-title($font-color-grey);
}

.a-text-copy-txt-subtitle {
  @include copy-txt-subtitle($font-color);
}

.a-text-copy-txt-info {
  @include copy-txt-info;
}

.a-text-link-content {
  @include link-content;
}

.a-text-link-copy {
  @include link-copy-txt;
}

.a-text-link-nav-generic {
  @include link-nav-generic;
}

.a-text-link-nav-primary {
  @include link-nav-primary;
}

.a-text-link-arrow {
  @include link-arrow;
}

.a-text-link-arrow-ext-left {
    @include link-arrow-ext-left($link-color);
}

.a-text-link-arrow-ext-right {
    @include link-arrow-ext-right($link-color);
}
