.mod-logo {

  @include flex-center();

  .m-logo {
    @include flex-center();
    justify-content: flex-start;
    flex-direction: column;
    margin: 0 20px 0 0;
    z-index: 1;

    &--myshare {

      > a {
        width: 93px;
        height: 29px;
        background: url('/assets/svgs/logo-myshare.svg') no-repeat;
        background-size: 100%;
      }

      &.hide {
        display: none;
      }
    }

    &--siemens {

      > a {
        width: 120px;
        height: 44px;
        margin-top: 20px;
        transform: translate(0,1px);
        background: url('/assets/svgs/sie-logo-petrol-rgb.svg') no-repeat;
        background-size: 100%;
      }

      > .logo-item {
        width: 140px;
        height: 44px;
        transform: translate(0,1px);
        background: url('/assets/svgs/logo-healthineers.svg') no-repeat;
        background-size: 100%;
      }

      &.hide {
        // display: none;
      }
    }

    &--healthineers {

      flex-direction: column;
      align-items: flex-start;

      &.show {
        display: flex;
      }

      > a {
        width: 117px;
        height: 28px;
        background: url('/assets/svgs/logo-healthineers.svg') no-repeat;
        background-size: 100%;
        //margin: 0 0 4px 0;
      }


      /*
      &:after {
        content: 'Powered by Siemens';
        @include logo-add-txt($grey-light);
        transform: translate(-1px, 0);
        display: block;
      }
      */
    }
  }
}
