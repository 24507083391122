.mod-text {

  width: 100%;
  @include clearfix();

  @include mq(print) {
    @include print-clean();
    @include print-section();
  }

  &--distance-small {
    @include vertical-margin-bottom(s);
  }

  &--distance-medium {
    @include vertical-margin-bottom(m);
  }

  &--distance-large {
    @include vertical-margin-bottom(l);
  }

  &--distance-x-large {
    margin-bottom: 125px !important;
  }

  > .a-text-headline-page {
    margin: 0 0 $v-distance-headline-sm 0;
  }

  > * {
    margin: 0 0 30px 0;

    .mod-lightbox  & {
      margin: 0 0 20px 0;
    }

    &:first-child {
      margin-top: 0;
    }

    &:last-child {
      margin-bottom: 0;
    }

    &.m-no-distance {
      margin: 0;
    }
  }

  h1 {
    @include headline-chapter-reg($font-color);
    margin: 40px 0 25px 0;
    transform: translate(-2px,0);
  }

  h2 {
    @include headline-chapter($font-color);
    margin: 40px 0 25px 0;
    transform: translate(-1px,0);
  }

  h3 {
    @include headline-component($font-color);
    margin: 40px 0 20px 0;
  }

  h4 {
    @include headline-txt($font-color);
    margin: 40px 0 15px 0;
  }


  > h5,
  > h6{
    @include copy-txt-chapter-title($font-color);
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    //@include print-clean();
    @include mq(print) {
      margin-top: 0;
    }
  }

  p,
  span,
  .m-copy {
    @include copy-txt($font-color);

    &--big {
      @include copy-txt-chapter-title($font-color);
      margin: 0 0 25px 0;
    }
  }

  a {
    @include link-content();

    &.m-link-block {
      display: inline-block;
    }
  }

  > ul {
    list-style: none;
    padding: 0;
    transform: translateX(0);

    li {
      @include copy-txt($font-color);
      margin: 0 0 4px 0;

      &:before {
        content: '';
        width: 4px;
        height: 4px;
        border-radius: 2px;
        background: $font-color;
        margin: 0 24px 3px 0;
        display: inline-block;
      }
    }
  }

  header {

    .m-title {
      @include copy-txt($font-color-grey-dark);
      margin: 0 0 5px 0;
      display: block;
    }

    .m-headline {
      @include headline-chapter-reg($font-color);
      margin: 0 0 10px 0;
      display: block;

      &--small {
        @include headline-component-bold($font-color);
      }
    }

    .m-intro {
      @include copy-txt-chapter-title($font-color);
      margin: 0 0 5px 0;
      display: block;
    }
  }

  footer {

    .m-author {
      @include copy-txt-small($font-color-grey);
      display: block;
    }

    .m-info {
      @include copy-txt-small($font-color-grey);
      display: block;
    }
  }

  .m-link {
    @include link-arrow-left($link-color);
  }

  blockquote,
  .m-quote {
    @include copy-txt-chapter-title-italic($link-color);
  }


  &--grid {
    @include grid-wrapper();
    @include vertical-margin-bottom(m);
  }

  &--grid-small {
    @include grid-wrapper();
    @include vertical-margin-bottom(m);

    > .m-content {
      margin: 0 auto;

      @include mq(desktop-s) {
        width: $grid-10;
      }

      @include mq(desktop-m) {
        width: $grid-8;
      }

      @include mq(print) {
        @include print-clean();
      }
    }

    &-left {

      > .m-content {
        margin: 0;
      }
    }
  }

  &--disclaimer {

    @include mq(print) {
      @include print-clean();
      border: none !important;

      *:not(a) {
        color: $font-color-grey !important;
      }
    }

    > * {
      &.m-headline {
        @include copy-txt-bold($font-color);
        margin-bottom: 0;
      }
    }

    > ul {
      li {
        margin: 0 0 0 10px;
        text-indent: -19px;
        padding-left: 10px;

        &:before {
          margin: 0 15px 3px 0;

          @include mq(print) {
            background: $font-color-grey !important;
          }
        }
      }
    }
  }


  // disclaimer text
  &--info {

    border-top: 1px solid $font-color-grey-super-extra-light;
    width: 100%;
    margin: 0;
    padding: 15px;
    position: relative;

    &:before {
      content: '*';
      left: 0;
      top: 15px;
      @include copy-txt-small($info-color);
      position: absolute;
    }

    > p,
    > ul {
      @include copy-txt-small($info-color);
      margin: 0 0 $v-distance-p 0;
    }

    > p {
      & + ul {
        margin-top: -20px;
      }
    }

    ul {
      list-style: none;
      padding: 0;
      transform: translateX(0);

      li {
        @include copy-txt-small($info-color);

        &:before {
          content: '';
          width: 2px;
          height: 2px;
          border-radius: 1px;
          background: $info-color;
          margin: 0 24px 3px 0;
          display: inline-block;
        }
      }
    }

    > * {
      &:last-child {
        margin-bottom: 0;
      }
    }

    &-light {
      &:before {
        @include copy-txt-small($white);
      }

      > p,
      > ul {
        @include copy-txt-small($white);
      }

      ul {
        li {
          @include copy-txt-small($white);

          &:before {
            background: $white;
          }
        }
      }
    }
  }

  &--sa {
    background: $tile-color-hsa;
  }
}
