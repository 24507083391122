@mixin icon($type) {

  &:before {
      font-family: 'siemens-webicons-webfont' !important;
      speak: none;
      font-style: normal;
      font-weight: normal;
      font-variant: normal;
      text-transform: none;
      line-height: 1;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;

      // siemens webfont icons
      @if $type == apps {
          content: '\e600';
      }
      @if $type == arrow-down-extrasmall {
          content: "\e601";
      }
      @if $type == arrow-left-extrasmall {
          content: "\e602";
      }
      @if $type == arrow-right-extrasmall {
          content: "\e603";
      }
      @if $type == arrow-up-extrasmall {
          content: "\e604";
      }
      @if $type == arrow-down-large {
          content: "\e605";
      }
      @if $type == arrow-left-large {
          content: "\e606";
      }
      @if $type == arrow-right-large {
          content: "\e607";
      }
      @if $type == arrow-up-large {
          content: "\e608";
      }
      @if $type == arrow-down-small {
          content: "\e609";
      }
      @if $type == arrow-left-small {
          content: "\e60a";
      }
      @if $type == arrow-right-small {
          content: "\e60b";
      }
      @if $type == arrow-up-small {
          content: "\e60c";
      }
      @if $type == arrow-to-top {
          content: "\e60d";
      }
      @if $type == at {
          content: "\e60e";
      }
      @if $type == bookmark {
          content: "\e60f";
      }
      @if $type == calculate {
          content: "\e610";
      }
      @if $type == calendar {
          content: "\e611";
      }
      @if $type == chat {
          content: "\e612";
      }
      @if $type == check-mark {
          content: "\e613";
      }
      @if $type == circle {
          content: "\e614";
      }
      @if $type == close {
          content: "\e615";
      }
      @if $type == control {
          content: "\e616";
      }
      @if $type == delete {
          content: "\e617";
      }
      @if $type == double-arrow-backward {
          content: "\e618";
      }
      @if $type == double-arrow-down {
          content: "\e619";
      }
      @if $type == double-arrow-forward {
          content: "\e61a";
      }
      @if $type == double-arrow-up {
          content: "\e61b";
      }
      @if $type == download {
          content: "\e61c";
      }
      @if $type == drag {
          content: "\e61d";
      }
      @if $type == email {
          content: "\e61f";
      }
      @if $type == ext {
          content: "\e620";
      }
      @if $type == facebook {
          content: "\e621";
      }
      @if $type == facebook-negative {
          content: "\e622";
      }
      @if $type == fax {
          content: "\e623";
      }
      @if $type == file {
          content: "\e624";
      }
      @if $type == avi {
          content: "\e625";
      }
      @if $type == doc {
          content: "\e626";
      }
      @if $type == eps {
          content: "\e627";
      }
      @if $type == jpg {
          content: "\e628";
      }
      @if $type == mov {
          content: "\e629";
      }
      @if $type == mp3 {
          content: "\e62a";
      }
      @if $type == mp4 {
          content: "\e62b";
      }
      @if $type == png {
          content: "\e62d";
      }
      @if $type == ppt {
          content: "\e62e";
      }
      @if $type == files {
          content: "\e62f";
      }
      @if $type == tif {
          content: "\e630";
      }
      @if $type == vcf {
          content: "\e631";
      }
      @if $type == xls {
          content: "\e632";
      }
      @if $type == zip {
          content: "\e633";
      }
      @if $type == forum {
          content: "\e634";
      }
      @if $type == fullscreen {
          content: "\e635";
      }
      @if $type == globe {
          content: "\e636";
      }
      @if $type == glossary {
          content: "\e637";
      }
      @if $type == google {
          content: "\e638";
      }
      @if $type == google-negative {
          content: "\e639";
      }
      @if $type == home {
          content: "\e63a";
      }
      @if $type == information {
          content: "\e63b";
      }
      @if $type == information-package {
          content: "\e63c";
      }
      @if $type == instagram {
          content: "\e63d";
      }
      @if $type == instagram-negative {
          content: "\e63e";
      }
      @if $type == legal-notice {
          content: "\e63f";
      }
      @if $type == level-up {
          content: "\e640";
      }
      @if $type == linkedin {
          content: "\e641";
      }
      @if $type == linkedin-negative {
          content: "\e642";
      }
      @if $type == list-view {
          content: "\e643";
      }
      @if $type == location-pin {
          content: "\e644";
      }
      @if $type == log-in {
          content: "\e645";
      }
      @if $type == log-out {
          content: "\e646";
      }
      @if $type == menu {
          content: "\e647";
      }
      @if $type == minus {
          content: "\e648";
      }
      @if $type == more {
          content: "\e649";
      }
      @if $type == navigation-gyroscope {
          content: "\e64a";
      }
      @if $type == navigation-mouse {
          content: "\e64b";
      }
      @if $type == newton_product-catalogue {
          content: "\e64c";
      }
      @if $type == outline {
          content: "\e64d";
      }
      @if $type == phone {
          content: "\e64e";
      }
      @if $type == play {
          content: "\e64f";
      }
      @if $type == plus {
          content: "\e650";
      }
      @if $type == print-doc {
        content: "\e651";
      }
      @if $type == rating {
          content: "\e652";
      }
      @if $type == region {
          content: "\e653";
      }
      @if $type == replay {
          content: "\e654";
      }
      @if $type == rss {
          content: "\e655";
      }
      @if $type == scaling {
          content: "\e656";
      }
      @if $type == search {
          content: "\e657";
      }
      @if $type == share-ios {
          content: "\e658";
      }
      @if $type == share-newton-android {
          content: "\e659";
      }
      @if $type == shopping-cart {
          content: "\e65a";
      }
      @if $type == show-location {
          content: "\e65b";
      }
      @if $type == single-arrow-backward {
          content: "\e65c";
      }
      @if $type == single-arrow-down {
          content: "\e65d";
      }
      @if $type == single-arrow-forward {
          content: "\e65e";
      }
      @if $type == single-arrow-up {
          content: "\e65f";
      }
      @if $type == slider {
          content: "\e660";
      }
      @if $type == social-feed {
          content: "\e661";
      }
      @if $type == speakers-off {
          content: "\e662";
      }
      @if $type == speakers-on {
          content: "\e663";
      }
      @if $type == subscribe {
          content: "\e664";
      }
      @if $type == theenergyblog {
          content: "\e665";
      }
      @if $type == theenergyblog-negative {
          content: "\e666";
      }
      @if $type == tile-view {
          content: "\e667";
      }
      @if $type == twitter {
          content: "\e668";
      }
      @if $type == twitter-negative {
          content: "\e669";
      }
      @if $type == user-account {
          content: "\e66a";
      }
      @if $type == video-chat {
          content: "\e66b";
      }
      @if $type == warning {
          content: "\e66c";
      }
      @if $type == youtube {
          content: "\e66d";
      }
      @if $type == youtube-negative {
          content: "\e66e";
      }
      @if $type == zoom {
          content: "\e66f";
      }
      @if $type == weibo {
          content: "\e900";
      }
      @if $type == weibo-negative {
          content: "\e901";
      }
      @if $type == youku {
          content: "\e902";
      }
      @if $type == youku-negative {
          content: "\e903";
      }
      @if $type == support {
          content: "\e904";
      }
      @if $type == service {
          content: "\e905";
      }

      @if $type == login-before {
          content: '';
          width: 14px;
          height: 14px;
          background: url('assets/svgs/icon-login.svg') no-repeat;
          background-size: 100%;
          margin-right: 6px;
      }

      @if $type == logout-before {
          content: '';
          width: 14px;
          height: 15px;
          background: url('assets/svgs/icon-logout.svg') no-repeat;
          background-size: 100%;
          margin-right: 6px;
      }
  }

  @if $type == welcome-mo {
      width: 49px;
      height: 50px;
      background: url('assets/svgs/icon-welcome-mo.svg') no-repeat;
      background-size: 100%;
  }

  @if $type == welcome-msp {
      width: 54px;
      height: 49px;
      background: url('assets/svgs/icon-welcome-msp.svg') no-repeat;
      background-size: 100%;
  }

  @if $type == welcome-mmt {
      width: 57px;
      height: 49px;
      background: url('assets/svgs/icon-welcome-mmt.svg') no-repeat;
      background-size: 100%;
  }

  @if $type == gototop {
      width: 24px;
      height: 25px;
      background: url('assets/svgs/icon-gototop.svg') no-repeat;
      background-size: 100%;
  }

  @if $type == profile {
      width: 15px;
      height: 20px;
      background: url('assets/svgs/icon-benefits-shareholder.svg') no-repeat;
      background-size: 100%;
  }

  @if $type == check {
      width: 19px;
      height: 15px;
      background: url('assets/svgs/icon-check.svg') no-repeat;
      background-size: 100%;
  }

  @if $type == gototop {
      width: 24px;
      height: 25px;
      background: url('assets/svgs/icon-gototop.svg') no-repeat;
      background-size: 100%;
  }

  @if $type == profile {
      width: 15px;
      height: 20px;
      background: url('assets/svgs/icon-benefits-shareholder.svg') no-repeat;
      background-size: 100%;
  }

  @if $type == login {
      width: 14px;
      height: 14px;
      background: url('assets/svgs/icon-login.svg') no-repeat;
      background-size: 100%;
  }

  @if $type == tile-arrow-light {
      width: 18px;
      height: 31px;
      background: url('assets/svgs/icon-tile-arrow-light.svg') no-repeat;
      background-size: 100%;
  }

  @if $type == tile-arrow-dark {
      width: 18px;
      height: 31px;
      background: url('assets/svgs/icon-tile-arrow-dark.svg') no-repeat;
      background-size: 100%;
  }

  @if $type == euro {
      width: 14px;
      height: 16px;
      background: url('assets/svgs/icon-euro.svg') no-repeat;
      background-size: 100%;
  }

  @if $type == close-page {
      width: 18px;
      height: 18px;
      background: url('assets/svgs/icon-close-page.svg') no-repeat;
      background-size: 100%;
  }

  @if $type == close-page-white {
      width: 18px;
      height: 18px;
      background: url('assets/svgs/icon-close-page-white.svg') no-repeat;
      background-size: 100%;
  }

  @if $type == close-page-dark {
      width: 18px;
      height: 18px;
      background: url('assets/svgs/icon-close-page-dark.svg') no-repeat;
      background-size: 100%;
  }

  @if $type == person-one {
      width: 16px;
      height: 21px;
      background: url('assets/svgs/icon-person-one.svg') no-repeat;
      background-size: 100%;
  }

  @if $type == person-two {
      width: 25px;
      height: 20px;
      background: url('assets/svgs/icon-person-two.svg') no-repeat;
      background-size: 100%;
  }

  @if $type == person-marker {
      width: 24px;
      height: 20px;
      background: url('assets/svgs/icon-person-marker.svg') no-repeat;
      background-size: 100%;
  }

  @if $type == line-chart {
      width: 12px;
      height: 12px;
      background: url('assets/svgs/icon-toggle-line-chart.svg') no-repeat;
      background-size: 100%;
  }

  @if $type == pie-chart {
      width: 12px;
      height: 12px;
      background: url('assets/svgs/icon-toggle-pie-chart.svg') no-repeat;
      background-size: 100%;
  }

  @if $type == info {
      width: 15px;
      height: 15px;
      background: url('assets/svgs/icon-info.svg') no-repeat;
      background-size: 100%;
  }

  @if $type == reload {
      width: 15px;
      height: 16px;
      background: url('assets/svgs/icon-reload.svg') no-repeat;
      background-size: 100%;
  }

  @if $type == back-button {
      width: 8px;
      height: 13px;
      background: url('assets/svgs/icon-back.svg') no-repeat;
      background-size: 100%;
  }


  // benefits
  @if $type == benefits-affordable {
      width: 64px;
      height: 64px;
      background: url('assets/svgs/icon-benefits-affordable.svg') no-repeat;
      background-size: 100%;
  }

  @if $type == benefits-company-subsidy {
      width: 64px;
      height: 64px;
      background: url('assets/svgs/icon-benefits-company-subsidy.svg') no-repeat;
      background-size: 100%;
  }

  @if $type == benefits-matching-shares {
      width: 64px;
      height: 64px;
      background: url('assets/svgs/icon-benefits-matching-shares.svg') no-repeat;
      background-size: 100%;
  }

  @if $type == benefits-dividends {
      width: 64px;
      height: 64px;
      background: url('assets/svgs/icon-benefits-dividends.svg') no-repeat;
      background-size: 100%;
  }

  @if $type == benefits-flexible {
      width: 64px;
      height: 64px;
      background: url('assets/svgs/icon-benefits-flexible.svg') no-repeat;
      background-size: 100%;
  }

  @if $type == benefits-lead {
      width: 64px;
      height: 64px;
      background: url('assets/svgs/icon-benefits-lead.svg') no-repeat;
      background-size: 100%;
  }

  @if $type == benefits-matching-shares {
      width: 64px;
      height: 64px;
      background: url('assets/svgs/icon-benefits-matching-shares.svg') no-repeat;
      background-size: 100%;
  }

  @if $type == benefits-motivate {
      width: 64px;
      height: 64px;
      background: url('assets/svgs/icon-benefits-motivate.svg') no-repeat;
      background-size: 100%;
  }

  @if $type == benefits-no-holding-period {
      width: 64px;
      height: 64px;
      background: url('assets/svgs/icon-benefits-no-holding-period.svg') no-repeat;
      background-size: 100%;
  }

  @if $type == benefits-no-personal-investment {
      width: 64px;
      height: 64px;
      background: url('assets/svgs/icon-benefits-no-personal-investment.svg') no-repeat;
      background-size: 100%;
  }

  @if $type == benefits-participation {
      width: 64px;
      height: 64px;
      background: url('assets/svgs/icon-benefits-participation.svg') no-repeat;
      background-size: 100%;
  }

  @if $type == benefits-recognition {
      width: 64px;
      height: 64px;
      background: url('assets/svgs/icon-benefits-recognition.svg') no-repeat;
      background-size: 100%;
  }

  @if $type == benefits-role-model {
      width: 64px;
      height: 64px;
      background: url('assets/svgs/icon-benefits-role-model.svg') no-repeat;
      background-size: 100%;
  }

  @if $type == benefits-share-rewards {
      width: 64px;
      height: 64px;
      background: url('assets/svgs/icon-benefits-share-rewards.svg') no-repeat;
      background-size: 100%;
  }

  @if $type == benefits-shareholder-rights {
      width: 64px;
      height: 64px;
      background: url('assets/svgs/icon-benefits-shareholder-rights.svg') no-repeat;
      background-size: 100%;
  }

  @if $type == benefits-shareholder {
      width: 64px;
      height: 64px;
      background: url('assets/svgs/icon-benefits-shareholder.svg') no-repeat;
      background-size: 100%;
  }

  @if $type == benefits-sell-to-cover {
      width: 60px;
      height: 60px;
      background: url('assets/svgs/icon-benefits-sell-to-cover.svg') no-repeat;
      background-size: 100%;
  }

  @if $type == benefits-sell-all {
      width: 61px;
      height: 64px;
      background: url('assets/svgs/icon-benefits-sell-all.svg') no-repeat;
      background-size: 100%;
  }

  @if $type == benefits-hold-all {
      width: 82px;
      height: 58px;
      background: url('assets/svgs/icon-benefits-hold-all.svg') no-repeat;
      background-size: 100%;
  }

  @if $type == benefits-default-option {
      width: 61px;
      height: 53px;
      background: url('assets/svgs/icon-benefits-default-option.svg') no-repeat;
      background-size: 100%;
  }

  @if $type == benefits-preliminary-tax {
      width: 56px;
      height: 58px;
      background: url('assets/svgs/icon-benefits-preliminary-tax.svg') no-repeat;
      background-size: 100%;
  }

  @if $type == benefits-calculation {
      width: 57px;
      height: 67px;
      background: url('assets/svgs/icon-benefits-calculation.svg') no-repeat;
      background-size: 100%;
  }

  @if $type == benefits-provision {
      width: 83px;
      height: 63px;
      background: url('assets/svgs/icon-benefits-provision.svg') no-repeat;
      background-size: 100%;
  }

  @if $type == benefits-tax-adjustments {
      width: 57px;
      height: 57px;
      background: url('assets/svgs/icon-benefits-tax-adjustments.svg') no-repeat;
      background-size: 100%;
  }


  // sps
  @if $type == sps-1 {
      width: 45px;
      height: 52px;
      background: url('assets/svgs/icon-sps-1.svg') no-repeat;
      background-size: 100%;
  }

  @if $type == sps-2 {
      width: 50px;
      height: 47px;
      background: url('assets/svgs/icon-sps-2.svg') no-repeat;
      background-size: 100%;
  }

  // description
  @if $type == set-smp-smp {
      width: 100%;
      background: url('assets/svgs/icon-set-smp-smp.svg') no-repeat;
      background-size: 100%;
      @include aspect-ratio(1024,107);
  }

  @if $type == set-smp-mip {
      width: 100%;
      background: url('assets/svgs/icon-set-smp-mip.svg') no-repeat;
      background-size: 100%;
      @include aspect-ratio(1024,107);
  }

  @if $type == set-smp-bap {
      width: 100%;
      background: url('assets/svgs/icon-set-smp-bap.svg') no-repeat;
      background-size: 100%;
      @include aspect-ratio(1024,107);
  }

  @if $type == set-sa-sa {
      width: 100%;
      background: url('assets/svgs/icon-set-sa-sa.svg') no-repeat;
      background-size: 100%;
      @include aspect-ratio(1026,109);
  }

  @if $type == set-sa-ceo {
      width: 100%;
      background: url('assets/svgs/icon-set-sa-ceo.svg') no-repeat;
      background-size: 100%;
      @include aspect-ratio(1024,108);
  }

  @if $type == set-sog {
      width: 100%;
      background: url('assets/svgs/icon-set-sog.svg') no-repeat;
      background-size: 100%;
      @include aspect-ratio(1027,98);
  }

  @if $type == set-ps {
      width: 100%;
      background: url('assets/svgs/icon-set-ps.svg') no-repeat;
      background-size: 100%;
      @include aspect-ratio(1026,109);
  }

  @if $type == set-dr {
      width: 100%;
      background: url('assets/svgs/icon-set-dr.svg') no-repeat;
      background-size: 100%;
      @include aspect-ratio(675,104);
  }

  @if $type == set-to-1 {
      width: 100%;
      background: url('assets/svgs/icon-set-to-1.svg') no-repeat;
      background-size: 100%;
      @include aspect-ratio(1024,150);
  }

  @if $type == set-to-2 {
      width: 100%;
      background: url('assets/svgs/icon-set-to-2.svg') no-repeat;
      background-size: 100%;
      @include aspect-ratio(1024,120);
  }

  @if $type == set-his {
      width: 100%;
      background: url('assets/svgs/icon-set-his.svg') no-repeat;
      background-size: 100%;
      @include aspect-ratio(1026,106);
  }

  @if $type == set-hsa {
      width: 100%;
      background: url('assets/svgs/icon-set-hsa.svg') no-repeat;
      background-size: 100%;
      @include aspect-ratio(1026,109);
  }

  @if $type == set-hsa-campaign {
      width: 100%;
      background: url('assets/svgs/icon-set-hsa-campaign.svg') no-repeat;
      background-size: 100%;
      @include aspect-ratio(1026,110);
  }


  @if $type == survey-success-1 {
      width: 92px;
      height: 89px;
      background: url('assets/svgs/icon-survey-success-1.svg') no-repeat;
      background-size: 100%;
  }

  @if $type == survey-success-2 {
      width: 112px;
      height: 105px;
      background: url('assets/svgs/icon-survey-success-2.svg') no-repeat;
      background-size: 100%;
  }

  @if $type == arrow-small {
      width: 5px;
      height: 7px;
      background: url('assets/svgs/icon-arrow-small.svg') no-repeat;
      background-size: 100%;
  }

  @if $type == arrow-expand {
      width: 13px;
      height: 8px;
      background: url('assets/svgs/icon-arrow-expand.svg') no-repeat;
      background-size: 100%;

      @include mq(desktop-l) {
          width: 13px * $desktop-l-factor;
          height: 8px * $desktop-l-factor;
      }
  }


  @if $type == arrow-expand-dark {
      width: 11px;
      height: 7.2px;
      background: url('assets/svgs/icon-arrow-expand-dark.svg') no-repeat;
      background-size: 100%;
  }

  @if $type == check-outline {
      width: 24px;
      height: 25px;
      background: url('assets/svgs/icon-check-outline.svg') no-repeat;
      background-size: 100%;

      @include mq(desktop-l) {
          width: 24px * $desktop-l-factor;
          height: 25px * $desktop-l-factor;
      }
  }

  @if $type == line-outline {
      width: 24px;
      height: 24px;
      background: url('assets/svgs/icon-line-outline.svg') no-repeat;
      background-size: 100%;

      @include mq(desktop-l) {
          width: 24px * $desktop-l-factor;
          height: 24px * $desktop-l-factor;
      }
  }

  @if $type == close-outline {
      width: 24px;
      height: 24px;
      background: url('assets/svgs/icon-close-outline.svg') no-repeat;
      background-size: 100%;

      @include mq(desktop-l) {
          width: 24px * $desktop-l-factor;
          height: 24px * $desktop-l-factor;
      }
  }

  @if $type == swiper-arrow-prev {
      width: 18px;
      height: 30px;
      background: url('assets/svgs/icon-swiper-arrow-prev.svg') no-repeat;
      background-size: 100%;
  }

  @if $type == swiper-arrow-next {
      width: 18px;
      height: 30px;
      background: url('assets/svgs/icon-swiper-arrow-next.svg') no-repeat;
      background-size: 100%;
  }

  @if $type == swiper-arrow-blue-prev {
      width: 18px;
      height: 30px;
      background: url('assets/svgs/icon-swiper-arrow-blue-prev.svg') no-repeat;
      background-size: 100%;
  }

  @if $type == swiper-arrow-blue-next {
      width: 18px;
      height: 30px;
      background: url('assets/svgs/icon-swiper-arrow-blue-next.svg') no-repeat;
      background-size: 100%;
  }


  @if $type == checkbox-check {
      width: 15px;
      height: 12px;
      background: url('assets/svgs/icon-checkbox-check.svg') no-repeat;
      background-size: 100%;
  }

  @if $type == checkbox-check-white {
      width: 15px;
      height: 12px;
      background: url('assets/svgs/icon-checkbox-check-white.svg') no-repeat;
      background-size: 100%;
  }

  @if $type == loupe {
      width: 19px;
      height: 19px;
      background: url('assets/svgs/icon-loupe.svg') no-repeat;
      background-size: 100%;
  }

  @if $type == reload-white {
      width: 12px;
      height: 12px;
      background: url('assets/svgs/icon-reload-white.svg') no-repeat;
      background-size: 100%;
  }

  @if $type == pdf {
      width: 16px;
      height: 24px;
      background: url('assets/svgs/icon-pdf.svg') no-repeat;
      background-size: 100%;
  }

  @if $type == pdf-dark {
      width: 16px;
      height: 24px;
      background: url('assets/svgs/icon-pdf-dark.svg') no-repeat;
      background-size: 100%;
  }

  @if $type == arrow-white {
      width: 9px;
      height: 13px;
      background: url('assets/svgs/icon-arrow-white.svg') no-repeat;
      background-size: 100%;
  }

  @if $type == arrow-blue {
      width: 9px;
      height: 13px;
      background: url('assets/svgs/icon-arrow-blue.svg') no-repeat;
      background-size: 100%;
  }

  @if $type == arrow-grey {
      width: 14px;
      height: 23px;
      background: url('assets/svgs/icon-arrow-grey.svg') no-repeat;
      background-size: 100%;
  }

  @if $type == calc-add {
      width: 20px;
      height: 21px;
      background: url('assets/svgs/icon-calc-add.svg') no-repeat;
      background-size: 100%;
  }

  @if $type == calc-divide {
      width: 20px;
      height: 20px;
      background: url('assets/svgs/icon-calc-divide.svg') no-repeat;
      background-size: 100%;
  }

  @if $type == calc-equal {
      width: 20px;
      height: 12px;
      background: url('assets/svgs/icon-calc-equal.svg') no-repeat;
      background-size: 100%;
  }

  @if $type == calc-multiply {
      width: 18px;
      height: 18px;
      background: url('assets/svgs/icon-calc-multiply.svg') no-repeat;
      background-size: 100%;
  }

  @if $type == notification {
      width: 30px;
      height: 31px;
      background: url('assets/svgs/icon-notification.svg') no-repeat;
      background-size: 100%;
  }

  @if $type == print {
      width: 19px;
      height: 18px;
      background: url('assets/svgs/icon-print.svg') no-repeat;
      background-size: 100%;
  }

  @if $type == print-blue {
      width: 19px;
      height: 18px;
      background: url('assets/svgs/icon-print-blue.svg') no-repeat;
      background-size: 100%;
  }

  @if $type == print-grey {
    width: 19px;
    height: 18px;
    background: url('assets/svgs/icon-print-grey.svg') no-repeat;
    background-size: 100%;
  }

  @if $type == download-icon {
      width: 12px;
      height: 16px;
      background: url('assets/svgs/icon-download.svg') no-repeat;
      background-size: 100%;
  }

  @if $type == download-icon-white {
      width: 12px;
      height: 16px;
      background: url('assets/svgs/icon-download-white.svg') no-repeat;
      background-size: 100%;
  }

  @if $type == arrow-ext {
      width: 8px;
      height: 8px;
      background: url('assets/svgs/icon-arrow-ext.svg') no-repeat;
      background-size: 100%;
  }

  @if $type == flag-uk {
      width: 71px;
      height: 71px;
      background: url('assets/svgs/icon-flag-uk.svg') no-repeat;
      background-size: 100%;
  }

  @if $type == flag-germany {
      width: 71px;
      height: 71px;
      background: url('assets/svgs/icon-flag-germany.svg') no-repeat;
      background-size: 100%;
  }

  @if $type == flag-china {
      width: 71px;
      height: 71px;
      background: url('assets/svgs/icon-flag-china.svg') no-repeat;
      background-size: 100%;
  }

  @if $type == flag-czech {
      width: 71px;
      height: 71px;
      background: url('assets/svgs/icon-flag-czech.svg') no-repeat;
      background-size: 100%;
  }

  @if $type == flag-france {
      width: 71px;
      height: 71px;
      background: url('assets/svgs/icon-flag-france.svg') no-repeat;
      background-size: 100%;
  }

  @if $type == flag-india {
      width: 71px;
      height: 71px;
      background: url('assets/svgs/icon-flag-india.svg') no-repeat;
      background-size: 100%;
  }

  @if $type == flag-italy {
      width: 71px;
      height: 71px;
      background: url('assets/svgs/icon-flag-italy.svg') no-repeat;
      background-size: 100%;
  }

  @if $type == flag-spain {
      width: 71px;
      height: 71px;
      background: url('assets/svgs/icon-flag-spain.svg') no-repeat;
      background-size: 100%;
  }

  @if $type == flag-usa {
      width: 71px;
      height: 71px;
      background: url('assets/svgs/icon-flag-usa.svg') no-repeat;
      background-size: 100%;
  }

  @if $type == flag-portuguese {
      width: 71px;
      height: 71px;
      background: url('assets/svgs/icon-flag-portuguese.svg') no-repeat;
      background-size: 100%;
  }

  @if $type == original-size {
      width: 20px;
      height: 20px;
      background: url('assets/svgs/icon-original-size.svg') no-repeat;
      background-size: 100%;
  }

  @if $type == edit {
      width: 38px;
      height: 57px;
      background: url('assets/svgs/icon-edit.svg') no-repeat;
      background-size: 100%;
  }

  @if $type == check-circle {
      width: 19px;
      height: 18px;
      background: url('assets/svgs/icon-check-circle.svg') no-repeat;
      background-size: 100%;
  }

  @if $type == quiz-right {
      width: 39px;
      height: 28px;
      background: url('assets/svgs/icon-quiz-right.svg') no-repeat;
      background-size: 100%;
  }

  @if $type == quiz-wrong {
      width: 29px;
      height: 28px;
      background: url('assets/svgs/icon-quiz-wrong.svg') no-repeat;
      background-size: 100%;
  }

  @if $type == academy-success-article {
      width: 138px;
      height: 138px;
      background: url('assets/svgs/icon-academy-success-article.svg') no-repeat;
      background-size: 100%;
  }

  @if $type == academy-success-finances {
      width: 138px;
      height: 138px;
      background: url('assets/svgs/icon-academy-success-finances.svg') no-repeat;
      background-size: 100%;
  }

  @if $type == academy-success-quiz {
      width: 138px;
      height: 138px;
      background: url('assets/svgs/icon-academy-success-quiz.svg') no-repeat;
      background-size: 100%;
  }

  @if $type == academy-success-shares {
      width: 138px;
      height: 138px;
      background: url('assets/svgs/icon-academy-success-shares.svg') no-repeat;
      background-size: 100%;
  }

  @if $type == academy-success-training {
      width: 138px;
      height: 138px;
      background: url('assets/svgs/icon-academy-success-training.svg') no-repeat;
      background-size: 100%;
  }

  @if $type == time-1 {
      width: 24px;
      height: 24px;
      background: url('assets/svgs/icon-time-1.svg') no-repeat;
      background-size: 100%;
  }

  @if $type == time-2 {
      width: 24px;
      height: 24px;
      background: url('assets/svgs/icon-time-2.svg') no-repeat;
      background-size: 100%;
  }

  @if $type == time-3 {
      width: 24px;
      height: 24px;
      background: url('assets/svgs/icon-time-3.svg') no-repeat;
      background-size: 100%;
  }

  @if $type == time-4 {
      width: 24px;
      height: 24px;
      background: url('assets/svgs/icon-time-4.svg') no-repeat;
      background-size: 100%;
  }

  @if $type == time-5 {
      width: 24px;
      height: 24px;
      background: url('assets/svgs/icon-time-5.svg') no-repeat;
      background-size: 100%;
  }

  @if $type == time-6 {
      width: 24px;
      height: 24px;
      background: url('assets/svgs/icon-time-6.svg') no-repeat;
      background-size: 100%;
  }

  @if $type == time-7 {
      width: 24px;
      height: 24px;
      background: url('assets/svgs/icon-time-7.svg') no-repeat;
      background-size: 100%;
  }

  @if $type == time-8 {
      width: 24px;
      height: 24px;
      background: url('assets/svgs/icon-time-8.svg') no-repeat;
      background-size: 100%;
  }

  @if $type == time-dark-1 {
      width: 24px;
      height: 24px;
      background: url('assets/svgs/icon-time-dark-1.svg') no-repeat;
      background-size: 100%;
  }

  @if $type == time-dark-2 {
      width: 24px;
      height: 24px;
      background: url('assets/svgs/icon-time-dark-2.svg') no-repeat;
      background-size: 100%;
  }

  @if $type == time-dark-3 {
      width: 24px;
      height: 24px;
      background: url('assets/svgs/icon-time-dark-3.svg') no-repeat;
      background-size: 100%;
  }

  @if $type == time-dark-4 {
      width: 24px;
      height: 24px;
      background: url('assets/svgs/icon-time-dark-4.svg') no-repeat;
      background-size: 100%;
  }

  @if $type == time-dark-5 {
      width: 24px;
      height: 24px;
      background: url('assets/svgs/icon-time-dark-5.svg') no-repeat;
      background-size: 100%;
  }

  @if $type == time-dark-6 {
      width: 24px;
      height: 24px;
      background: url('assets/svgs/icon-time-dark-6.svg') no-repeat;
      background-size: 100%;
  }

  @if $type == time-dark-7 {
      width: 24px;
      height: 24px;
      background: url('assets/svgs/icon-time-dark-7.svg') no-repeat;
      background-size: 100%;
  }

  @if $type == time-dark-8 {
      width: 24px;
      height: 24px;
      background: url('assets/svgs/icon-time-dark-8.svg') no-repeat;
      background-size: 100%;
  }

  @if $type == severity-easy {
      width: 24px;
      height: 24px;
      background: url('assets/svgs/icon-severity-easy.svg') no-repeat;
      background-size: 100%;
  }

  @if $type == severity-medium {
      width: 24px;
      height: 24px;
      background: url('assets/svgs/icon-severity-medium.svg') no-repeat;
      background-size: 100%;
  }

  @if $type == severity-difficult {
      width: 24px;
      height: 24px;
      background: url('assets/svgs/icon-severity-difficult.svg') no-repeat;
      background-size: 100%;
  }


  @if $type == arrow-white-big {
      width: 32px;
      height: 33px;
      transform: translate(10px,0);
      background: url('assets/svgs/icon-arrow-white-big.svg') no-repeat;
      background-size: 100%;
  }

  @if $type == two-rectangle {
      width: 47px;
      height: 47px;
      background: url('assets/svgs/icon-two-rectangle.svg') no-repeat;
      background-size: 100%;
  }

  @if $type == triangle-active {
      width: 13px;
      height: 11px;
      background: url('assets/svgs/icon-triangle-active.svg') no-repeat;
      background-size: 100%;
  }

  @if $type == triangle {
      width: 13px;
      height: 11px;
      background: url('assets/svgs/icon-triangle.svg') no-repeat;
      background-size: 100%;
  }
}
