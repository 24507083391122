.mod-header {
  @include grid-wrapper();
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  position: relative;

  .m-logo-wrapper {
    @include flex-center();
    flex-direction: row;
  }
}
