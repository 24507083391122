@font-face {
  font-family: "Siemens Sans";
  src: url(assets/fonts/SiemensSans-Roman.otf);
}

@font-face {
  font-family: "Siemens Sans Bold";
  src: url(assets/fonts/SiemensSans-Bold.otf);
}

@font-face {
  font-family: "Siemens Serif Bold";
  src: url(assets/fonts/SiemensSerif-Bold.otf);
}

@font-face {
  font-family: "Siemens Sans Italic";
  src: url(assets/fonts/SiemensSans-Italic.otf);
}

@font-face {
  font-family: "Siemens Black";
  src: url(assets/fonts/SiemensSans-Black.otf);
}

@font-face {
  font-family: "Siemens Sans";
  src: url(assets/fonts/SiemensSans-Roman.otf);
}



/**
*  ====================== Fontstack ======================
*/
@mixin fontstack($typo: siemens-sans, $size: 16px, $line: 1, $letter: 1px, $color: $font-color, $weight: 400) {

  @if $typo == siemens-sans {
      font-family: "Siemens Sans";
  }

  @if $typo == siemens-sans-bold {
      font-family: "Siemens Sans Bold";
  }

  @if $typo == siemens-serif-bold {
      font-family: "Siemens Serif Bold";
  }

  @if $typo == siemens-sans-italic {
      font-family: "Siemens Sans Italic";
  }

  @if $typo == siemens-sans-black {
      font-family: "Siemens Black";
  }

  font-size: $size;
  line-height: $line;
  letter-spacing: $letter;
  font-weight: $weight;
  color: #050409;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  transition: font-size 0.3s ease-in-out;
}

@mixin headline-page($color) {
  @include fontstack(siemens-sans, 46px, 57px, 0, $color, 400);

  @include mq(desktop-l) {
      @include fontstack(siemens-sans, 46px * $desktop-l-factor, 57px * $desktop-l-factor, 0, $color, 400);
  }

  @include mq(print) {
      @include fontstack(siemens-sans, 46px, 57px, 0, $color, 400);
      margin-bottom: 25px !important;
  }
}

@mixin headline-chapter($color) {
  @include fontstack(siemens-sans-bold, 30px, 36px, 0, $color, 400);

  @include mq(desktop-l) {
      @include fontstack(siemens-sans-bold, 20px * $desktop-l-factor, 36px * $desktop-l-factor, 0, $color, 400);
  }
}

@mixin headline-chapter-reg($color) {
  @include fontstack(siemens-sans, 30px, 36px, 0, $color, 400);

  @include mq(desktop-l) {
      @include fontstack(siemens-sans, 30px * $desktop-l-factor, 36px * $desktop-l-factor, 0, $color, 400);
  }
}

@mixin headline-component($color) {
  @include fontstack(siemens-sans, 24px, 30px, 0, $color, 400);

  @include mq(desktop-l) {
      @include fontstack(siemens-sans, 20px * $desktop-l-factor, 30px * $desktop-l-factor, 0, $color, 400);
  }
}

@mixin headline-component-bold($color) {
  @include fontstack(siemens-sans-bold, 24px, 34px, 0, $color, 400);

  @include mq(desktop-l) {
      @include fontstack(siemens-sans-bold, 24px * $desktop-l-factor, 34px * $desktop-l-factor, 0, $color, 400);
  }
}

@mixin headline-component-small($color) {
  @include fontstack(siemens-sans, 20px, 28px, 0, $color, 400);

  @include mq(desktop-l) {
      @include fontstack(siemens-sans, 20px * $desktop-l-factor, 28px * $desktop-l-factor, 0, $color, 400);
  }
}

@mixin headline-component-small-bold($color) {
  @include fontstack(siemens-sans-bold, 20px, 28px, 0, $color, 400);

  @include mq(desktop-l) {
      @include fontstack(siemens-sans-bold, 20px * $desktop-l-factor, 28px * $desktop-l-factor, 0, $color, 400);
  }
}

@mixin headline-txt($color) {
  @include fontstack(siemens-sans-bold, 18px, 24px, 0, $color, 400);

  @include mq(desktop-l) {
      @include fontstack(siemens-sans-bold, 18px * $desktop-l-factor, 24px * $desktop-l-factor, 0, $color, 400);
  }
}

@mixin copy-txt($color) {
  @include fontstack(siemens-sans, 14px, 20px, 0, $color, 400);

  @include mq(desktop-l) {
      @include fontstack(siemens-sans, 14px * $desktop-l-factor, 20px * $desktop-l-factor, 0, $color, 400);
  }
}

@mixin copy-txt-bold($color) {
  @include fontstack(siemens-sans-bold, 14px, 20px, 0, $color, 400);

  @include mq(desktop-l) {
      @include fontstack(siemens-sans-bold, 14px * $desktop-l-factor, 20px * $desktop-l-factor, 0, $color, 400);
  }
}

@mixin copy-txt-black($color) {
  @include fontstack(siemens-sans-black, 14px, 20px, 0, $color, 400);

  @include mq(desktop-l) {
      @include fontstack(siemens-sans-black, 14px * $desktop-l-factor, 20px * $desktop-l-factor, 0, $color, 400);
  }
}

@mixin copy-txt-chapter-title($color) {
  @include fontstack(siemens-sans, 14px, 20px, 0, $color, 400);

  @include mq(desktop-s) {
      @include fontstack(siemens-sans, 14px * $desktop-l-factor, 20px * $desktop-l-factor, 0, $color, 400);
  }

  @include mq(desktop-l) {
      @include fontstack(siemens-sans, 18px * $desktop-l-factor, 24px * $desktop-l-factor, 0, $color, 400);
  }
}

@mixin copy-txt-chapter-title-italic($color) {
  @include fontstack(siemens-sans-italic, 18px, 24px, 0, $color, 400);

  @include mq(desktop-l) {
      @include fontstack(siemens-sans-italic, 18px * $desktop-l-factor, 24px * $desktop-l-factor, 0, $color, 400);
  }
}

@mixin copy-txt-subtitle($color) {
  @include fontstack(siemens-sans-bold, 18px, 24px, 0, $color, 400);

  @include mq(desktop-l) {
      @include fontstack(siemens-sans-bold, 18px * $desktop-l-factor, 24px * $desktop-l-factor, 0, $color, 400);
  }
}

@mixin copy-txt-info {
  @include fontstack(siemens-sans, 14px, 20px, 0, $font-color-grey, 400);

  @include mq(desktop-l) {
      @include fontstack(siemens-sans, 14px * $desktop-l-factor, 20px * $desktop-l-factor, 0, $font-color-grey, 400);
  }
}

@mixin copy-txt-small($color) {
  @include fontstack(siemens-sans, 12px, 18px, 0, $color, 400);

  @include mq(desktop-l) {
      @include fontstack(siemens-sans, 12px * $desktop-l-factor, 18px * $desktop-l-factor, 0, $color, 400);
  }
}

@mixin copy-txt-small-bold($color) {
  @include fontstack(siemens-sans-bold, 12px, 18px, 0, $color, 400);

  @include mq(desktop-l) {
      @include fontstack(siemens-sans-bold, 12px * $desktop-l-factor, 18px * $desktop-l-factor, 0, $color, 400);
  }
}

@mixin copy-txt-x-small($color) {
  @include fontstack(siemens-sans, 11px, 14px, 0, $color, 400);

  @include mq(desktop-l) {
      @include fontstack(siemens-sans, 11px * $desktop-l-factor, 14px * $desktop-l-factor, 0, $color, 400);
  }
}

@mixin copy-txt-extra-small($color) {
  @include fontstack(siemens-sans, 10px, 13px, 0, $color, 400);

  @include mq(desktop-l) {
      @include fontstack(siemens-sans, 10px * $desktop-l-factor, 13px * $desktop-l-factor, 0, $color, 400);
  }
}

@mixin copy-txt-extra-small-bold($color) {
  @include fontstack(siemens-sans-bold, 10px, 13px, 0, $color, 400);

  @include mq(desktop-l) {
      @include fontstack(siemens-sans-bold, 10px * $desktop-l-factor, 13px * $desktop-l-factor, 0, $color, 400);
  }
}

@mixin footer-txt($color) {
  @include fontstack(siemens-sans, 14px, 20px, 0, $color, 400);

  @include mq(desktop-l) {
      @include fontstack(siemens-sans, 14px * $desktop-l-factor, 20px * $desktop-l-factor, 0, $color, 400);
  }
}


@mixin copy-txt-italic($color) {
  @include fontstack(siemens-sans-italic, 14px, 20px, 0, $color, 400);

  @include mq(desktop-l) {
      @include fontstack(siemens-sans-italic, 14px * $desktop-l-factor, 20px * $desktop-l-factor, 0, $color, 400);
  }
}

@mixin nav-txt-login($color) {
  @include fontstack(siemens-sans-bold, 14px, 16px, 0, $color, 400);

  @include mq(desktop-l) {
      @include fontstack(siemens-sans-bold, 14px * $desktop-l-factor, 16px * $desktop-l-factor, 0, $color, 400);
  }
}

@mixin nav-txt-circle-txt($color) {
  @include fontstack(siemens-sans-black, 11px, 1, 0, $color, 400);
}

@mixin tile-chart-table-head($color) {
  @include fontstack(siemens-sans-bold, 11px, 14px, 0.1px, $color, 400);

  @include mq(desktop-l) {
      @include fontstack(siemens-sans-bold, 11px * $desktop-l-factor, 1 * $desktop-l-factor, 0, $color, 400);
  }
}

@mixin link-txt-small($color) {
  @include fontstack(siemens-sans, 12px, 20px, 0, $color, 400);
  text-decoration: underline;
  position: relative;

  @include mq(desktop-l) {
      @include fontstack(siemens-sans, 12px * $desktop-l-factor, 20px * $desktop-l-factor, 0, $color, 400);
  }
}

@mixin link-content {
  @include fontstack(siemens-sans, 14px, 20px, 0, $link-color, 400);
  text-decoration: none;
  position: relative;

  @include mq(desktop-l) {
      @include fontstack(siemens-sans, 14px * $desktop-l-factor, 20px * $desktop-l-factor, 0, $link-color, 400);
  }

  &:hover,
  .active {
      color: $link-color-hover;
      border-bottom: 1px solid $link-color-hover;
  }
}

@mixin link-copy-txt {
  @include fontstack(siemens-sans, 14px, 20px, 0, $link-color, 400);
  text-decoration: none;
  position: relative;
  border-bottom: 1px solid $link-color;

  @include mq(desktop-l) {
      @include fontstack(siemens-sans, 14px * $desktop-l-factor, 20px * $desktop-l-factor, 0, $link-color, 400);
  }

  &:hover,
  .active {
      color: $link-color-hover;
      border-bottom: 1px solid $link-color-hover;
  }
}

@mixin link-nav-generic {
  @include fontstack(siemens-sans, 14px, 20px, 0, $font-color-grey, 400);
  text-decoration: none;
  position: relative;

  @include mq(desktop-l) {
      @include fontstack(siemens-sans, 14px * $desktop-l-factor, 20px * $desktop-l-factor, 0, $font-color-grey, 400);
  }

  &:hover,
  .active {
      color: $link-color-hover;
      border-bottom: 1px solid $link-color-hover;
  }
}

@mixin link-nav-primary {
  @include fontstack(siemens-sans, 14px, 20px, 0, $font-color-grey, 400);
  text-decoration: none;
  position: relative;

  @include mq(desktop-s) {
      //font-size: 18px;
      //line-height: 24px;
  }

  @include mq(desktop-l) {
      @include fontstack(siemens-sans, 14px * $desktop-l-factor, 20px * $desktop-l-factor, 0, $font-color-grey, 400);
  }
}

@mixin profile-name($color) {
  @include fontstack(siemens-sans, 18px, 24px, 0, $color, 400);
  text-decoration: none;
  position: relative;

  @include mq(desktop-l) {
      @include fontstack(siemens-sans, 18px * $desktop-l-factor, 24px * $desktop-l-factor, 0, $color, 400);
  }
}


// Arrow Links

@mixin link-arrow {
  @include fontstack(siemens-sans, 14px, 16px, 0, $blue, 400);
  text-decoration: none;
  position: relative;
  display: block;

  @include mq(desktop-l) {
      font-size: 18px;
      line-height: 21px;
  }

  &.a-icon {
      &:before {
          font-size: 18px;
          color: $blue;
          transform: translate(-6px, 3px);
          display: inline-block;

          @include mq(desktop-l) {
              transform: translate(-6px, 2px);
          }
      }
  }

  &:hover,
  .active {
      color: $blue-dark;

      &.a-icon {
          &:before {
              color: $blue-dark;
          }
      }
  }
}


@mixin link-arrow-left-small($color) {
  @include fontstack(siemens-sans, 10px, 13px, 0, $color, 400);
  text-decoration: none;
  @include icon(arrow-right-small);
  text-indent: -13px;
  padding-left: 10px;
  display: inline-block;
  cursor: pointer;
  position: relative;

  @include mq(desktop-l) {
      @include fontstack(siemens-sans, 10px * $desktop-l-factor, 13px * $desktop-l-factor, 0, $color, 400);
  }

  &:before {
      text-decoration: none;
      transform: translate(0, 1px);
      width: 10px;
      margin-right: 3px;
      display: inline-block;
      text-indent: 0;
  }

  &:hover {
      text-decoration: underline;
  }
}



@mixin link-arrow-left($color) {
  @include fontstack(siemens-sans, 14px, 16px, 0, $color, 400);
  text-decoration: none;
  @include icon(arrow-right-small);
  text-indent: -20px;
  padding-left: 17px;
  display: inline-block;
  cursor: pointer;
  position: relative;

  @include mq(desktop-l) {
      @include fontstack(siemens-sans, 14px * $desktop-l-factor, 16px * $desktop-l-factor, 0, $color, 400);
  }

  &:before {
      text-decoration: none;
      transform: translate(0, 1px);
      width: 17px;
      margin-right: 3px;
      display: inline-block;
      text-indent: 0;
  }

  &:hover {
      text-decoration: underline;
  }
}


@mixin link-arrow-ext-left-small($color) {
  @include fontstack(siemens-sans, 10px, 13px, 0, $color, 400);
  text-decoration: none;
  @include icon(ext);
  text-indent: -13px;
  padding-left: 10px;
  display: inline-block;
  cursor: pointer;
  position: relative;

  @include mq(desktop-l) {
      @include fontstack(siemens-sans, 10px * $desktop-l-factor, 13px * $desktop-l-factor, 0, $color, 400);
  }

  &:before {
      text-decoration: none;
      transform: translate(0, 1px);
      width: 10px;
      margin-right: 3px;
      display: inline-block;
      text-indent: 0;
  }

  &:hover {
      text-decoration: underline;
  }
}


@mixin link-arrow-ext-left($color) {
  @include fontstack(siemens-sans, 14px, 16px, 0, $color, 400);
  text-decoration: none;
  @include icon(ext);
  text-indent: -20px;
  padding-left: 17px;
  display: inline-block;
  cursor: pointer;
  position: relative;

  @include mq(desktop-l) {
      @include fontstack(siemens-sans, 14px * $desktop-l-factor, 16px * $desktop-l-factor, 0, $color, 400);
  }

  &:before {
      text-decoration: none;
      transform: translate(0, 1px);
      width: 17px;
      margin-right: 3px;
      display: inline-block;
      text-indent: 0;
  }

  &:hover {
      text-decoration: underline;
  }
}


@mixin link-arrow-ext-right-small($color) {
  @include fontstack(siemens-sans, 10px, 13px, 0, $color, 400);
  position: relative;
  cursor: pointer;

  &:after {
      content: '';
      width: 6px !important;
      height: 6px !important;
      @include icon(arrow-ext);
      margin: 0 0 0 4px;
      display: inline-block;
  }

  @include mq(desktop-l) {
      @include fontstack(siemens-sans, 10px * $desktop-l-factor, 13px * $desktop-l-factor, 0, $color, 400);

      &:after {
          width: 8px !important;
          height: 8px !important;
      }
  }

  &:hover {
      text-decoration: underline;
  }
}


@mixin link-arrow-ext-right($color) {
  @include fontstack(siemens-sans, 14px, 16px, 0, $color, 400);
  position: relative;
  cursor: pointer;

  @include mq(desktop-l) {
      @include fontstack(siemens-sans, 14px * $desktop-l-factor, 16px * $desktop-l-factor, 0, $color, 400);
  }

  &:after {
      content: '';
      width: 8px !important;
      height: 8px !important;
      @include icon(arrow-ext);
      margin: 0 0 0 5px;
      display: inline-block;
  }

  @include mq(desktop-l) {
      @include fontstack(siemens-sans, 14px * $desktop-l-factor, 16px * $desktop-l-factor, 0, $color, 400);

      &:after {
          width: 10px !important;
          height: 10px !important;
      }
  }

  &:hover {
      text-decoration: underline;
  }
}


@mixin link-download-small($color) {
  @include fontstack(siemens-sans, 10px, 13px, 0, $color, 400);
  display: inline-block;
  @include icon(download);
  position: relative;
  cursor: pointer;

  @include mq(desktop-l) {
      @include fontstack(siemens-sans, 10px * $desktop-l-factor, 13px * $desktop-l-factor, 0, $color, 400);
  }

  &:before {
      transform: translate(-1px, 1px);
      margin: 0 0 0 4px;
      display: inline-block;
      float: right;
  }

  &:hover {
      text-decoration: underline;
  }
}



// Quote
@mixin quote($color) {
  @include fontstack(siemens-serif-bold, 120px, 36px, 0, $color, 400);
}


// Table
@mixin table-txt-extra-small($color) {
  @include fontstack(siemens-sans, 10px, 13px, 0, $color, 400);

  @include mq(desktop-s) {
      @include fontstack(siemens-sans, 10px, 13px, 0, $color, 400);
  }

  @include mq(desktop-m) {
      @include fontstack(siemens-sans, 12px, 16px, 0, $color, 400);
  }

  @include mq(desktop-l) {
      @include fontstack(siemens-sans, 14px, 18px, 0, $color, 400);
  }
}

@mixin table-txt-small($color) {
  @include fontstack(siemens-sans, 10px, 16px, 0, $color, 400);
  opacity: 1;

  @include mq(desktop-s) {
      @include fontstack(siemens-sans, 12px, 18px, 0, $color, 400);
  }

  @include mq(desktop-m) {
      @include fontstack(siemens-sans, 14px, 20px, 0, $color, 400);
  }

  @include mq(desktop-l) {
      @include fontstack(siemens-sans, 16px, 23px, 0, $color, 400);
  }
}

@mixin table-txt-small-bold($color) {
  @include fontstack(siemens-sans-bold, 10px, 16px, 0, $color, 400);
  opacity: 1;

  @include mq(desktop-s) {
      @include fontstack(siemens-sans-bold, 12px, 18px, 0, $color, 400);
  }

  @include mq(desktop-m) {
      @include fontstack(siemens-sans-bold, 14px, 20px, 0, $color, 400);
  }

  @include mq(desktop-l) {
      @include fontstack(siemens-sans-bold, 16px, 23px, 0, $color, 400);
  }
}

@mixin table-txt-small-italic($color) {
  @include fontstack(siemens-sans-italic, 10px, 16px, 0, $color, 400);

  @include mq(desktop-s) {
      @include fontstack(siemens-sans-italic, 12px, 18px, 0, $color, 400);
  }

  @include mq(desktop-m) {
      @include fontstack(siemens-sans-italic, 14px, 20px, 0, $color, 400);
  }

  @include mq(desktop-l) {
      @include fontstack(siemens-sans-italic, 16px, 23px, 0, $color, 400);
  }
}


@mixin table-txt($color) {
  @include fontstack(siemens-sans, 10px, 16px, 0, $color, 400);

  @include mq(desktop-s) {
      @include fontstack(siemens-sans, 14px, 20px, 0, $color, 400);
  }

  @include mq(desktop-m) {
      @include fontstack(siemens-sans, 18px, 24px, 0, $color, 400);
  }

  @include mq(desktop-l) {
      @include fontstack(siemens-sans, 20px, 26px, 0, $color, 400);
  }
}

@mixin table-txt-bold($color) {
  @include fontstack(siemens-sans-bold, 10px, 16px, 0, $color, 400);

  @include mq(desktop-s) {
      @include fontstack(siemens-sans-bold, 14px, 20px, 0, $color, 400);
  }

  @include mq(desktop-m) {
      @include fontstack(siemens-sans-bold, 18px, 24px, 0, $color, 400);
  }

  @include mq(desktop-l) {
      @include fontstack(siemens-sans-bold, 20px, 26px, 0, $color, 400);
  }
}

@mixin table-txt-italic($color) {
  @include fontstack(siemens-sans-italic, 10px, 16px, 0, $color, 400);

  @include mq(desktop-s) {
      @include fontstack(siemens-sans-italic, 14px, 20px, 0, $color, 400);
  }

  @include mq(desktop-m) {
      @include fontstack(siemens-sans-italic, 18px, 24px, 0, $color, 400);
  }

  @include mq(desktop-l) {
      @include fontstack(siemens-sans-italic, 20px, 26px, 0, $color, 400);
  }
}

@mixin table-txt-download($color) {
  @include fontstack(siemens-sans, 12px, 16px, 0, $color, 400);

  @include mq(desktop-s) {
      @include fontstack(siemens-sans, 14px, 20px, 0, $color, 400);
  }

  @include mq(desktop-l) {
      @include fontstack(siemens-sans, 14px * $desktop-l-factor, 20px * $desktop-l-factor, 0, $color, 400);
  }
}



// Chart
@mixin chart-txt-nav($color) {
  @include fontstack(siemens-sans-bold, 14px, 20px, 0, $color, 400);
  position: relative;
  cursor: pointer;

  @include mq(desktop-l) {
      @include fontstack(siemens-sans-bold, 14px * $desktop-l-factor, 20px * $desktop-l-factor, 0, $color, 400);
  }
}


@mixin chart($color) {
  @include fontstack(siemens-sans, 14px, 20px, 0, $color, 400);

  @include mq(desktop-l) {
      font-size: 18px;
      line-height: 26px;
  }
}

@mixin chart-bold($color) {
  @include fontstack(siemens-sans-bold, 14px, 20px, 0, $color, 400);

  @include mq(desktop-l) {
      font-size: 18px;
      line-height: 26px;
  }
}

@mixin chart-small($color) {
  @include fontstack(siemens-sans, 10px, 20px, 0, $color, 400);

  @include mq(desktop-l) {
      font-size: 13px;
      line-height: 26px;
  }
}

@mixin chart-bold-small($color) {
  @include fontstack(siemens-sans-bold, 10px, 20px, 0, $color, 400);

  @include mq(desktop-l) {
      font-size: 13px;
      line-height: 26px;
  }
}

@mixin survey-small {
  @include fontstack(siemens-sans-bold, 12px, 20px, 0, $font-color, 400);

  @include mq(desktop-l) {
      font-size: 15px;
      line-height: 23px;
  }
}

@mixin price-big-txt($color) {
  @include fontstack(siemens-sans, 28px, 34px, 0, $color, 400);
  overflow: hidden;
  text-overflow: ellipsis;

  @include mq(desktop-s) {
      font-size: 38px;
      line-height: 47px;
  }

  @include mq(desktop-m) {
      font-size: 46px;
      line-height: 57px;
  }

  @include mq(desktop-l) {
      font-size: 55px;
      line-height: 70px;
  }
}

@mixin number-circle-loader($color) {
  @include fontstack(siemens-sans-bold, 24px, 30px, 0, $color, 400);
}

@mixin step-txt($color) {
  @include fontstack(siemens-sans-bold, 14px, 0, 0, $color, 400);

  @include mq(desktop-l) {
      @include fontstack(siemens-sans-bold, 10px * $desktop-l-factor, 0px * $desktop-l-factor, 0, $color, 400);
  }
}

@mixin sps-campaign-info($color) {
  @include fontstack(siemens-sans-bold, 18px, 26px, 0, $color, 400);

  @include mq(desktop-s) {
      @include fontstack(siemens-sans-bold, 24px, 34px, 0, $color, 400);
  }

  @include mq(desktop-l) {
      @include fontstack(siemens-sans-bold, 24px * $desktop-l-factor, 34px * $desktop-l-factor, 0, $color, 400);
  }
}

@mixin list-item-bullet($color) {
  @include fontstack(siemens-sans, 14px, 20px, 0, $color, 400);
  text-decoration: none;
  text-indent: -29px;
  padding: 0 40px 0 56px;
  display: inline-block;
  cursor: pointer;
  position: relative;
  margin: 0 0 24px 0;

  &:last-of-type {
      margin-bottom: 0;
  }

  @include mq(desktop-l) {
      @include fontstack(siemens-sans, 14px * $desktop-l-factor, 20px * $desktop-l-factor, 0, $color, 400);
  }

  &:before {
      content: '';
      width: 4px;
      height: 4px;
      margin-right: 25px;
      border-radius: 2px;
      background: $color;
      display: inline-block;
  }
}

@mixin list-item-bullet-small($color) {
  @include fontstack(siemens-sans, 12px, 18px, 0, $color, 400);
  text-decoration: none;
  text-indent: -12px;
  padding: 0 0 0 28px;
  display: inline-block;
  cursor: pointer;
  position: relative;
  margin: 0 0 4px 0;

  &:last-of-type {
      margin-bottom: 0;
  }

  @include mq(desktop-l) {
      @include fontstack(siemens-sans, 12px * $desktop-l-factor, 18px * $desktop-l-factor, 0, $color, 400);
  }

  &:before {
      content: '';
      width: 2px;
      height: 2px;
      margin: 0 10px 3px 0;
      border-radius: 1px;
      background: $color;
      display: inline-block;

      @include mq(desktop-l) {
          margin: 0 10px 5px 0;
      }
  }
}

@mixin headline-big($color) {
  @include fontstack(siemens-sans, 46px, 57px, 0, $color, 400);

  @include mq(desktop-m) {
      @include fontstack(siemens-sans, 56px, 70px, 0, $color, 400);
  }

  @include mq(desktop-l) {
      @include fontstack(siemens-sans, 56px * $desktop-l-factor, 70px * $desktop-l-factor, 0, $color, 400);
  }
}

@mixin logo-add-txt($color) {
  @include fontstack(siemens-sans, 11px, 10px, 0, $color, 400);
}

@mixin headline-discover-tiles($color) {
  @include fontstack(siemens-sans-bold, 22px, 30px, 0, $color, 400);

  @include mq(desktop-l) {
      @include fontstack(siemens-sans-bold, 22px * $desktop-l-factor, 30px * $desktop-l-factor, 0, $color, 400);
  }
}
