// COLORS
$white: #ffffff;
$black: #000000;
$black-light: #222A2E;
$black-extra-light: #4A4A4A;

$font-color: #050409;
$font-color-grey-dark: #555f69;
$font-color-grey: #75899A;
$font-color-grey-extra-light: #becdd7;
$font-color-grey-super-extra-light: #ebf0f5;
$input-border: #cdd9e1;
$input-border-top: #879baa;
$font-color-calc-value: #21292D;

$nav-light: #B1C2CE;

$bg-survey-1: #E6ECF2;
$bg-survey-2: #E1E7ED;

$quiz-blue: #E6ECF2;
$quiz-blue-dark: #005578;

$tile-msp-border: #E6ECF2;

$info-color: #9EB0BF;

$logo-color: #41aaaa;

$petrol-color: #009999;

$green-chart: #16A605;
$red-chart: #E4630D;
$blue-chart: #43B1CE;
$blue-form: #08426A;

$green-managing-tasks: #26af2f;
$blue-managing-tasks: #00baff;

$link-color: #2387aa;
$link-color-hover: #00557D;

$bg-grey: #555f69;
$bg-grey-light: #ebf0f5;

$grey: #555f69;
$grey-light: #788791;

$orange: #faa50a;
$orange-dark: #eb780a;

$blue: #2387aa;
$blue-dark: #005578;

$green: #9AA811;
$red: #A82216;
$navigation-active: #1F749A;

$nav-bottom-line: #DFE6ED;
$tooltip-underline: #95a3ad;

$document-border: #65747E;
$checkbox-border-active: #094C74;
$doc-repo-hover: #43B1CE;
$color-sps-hover: #9b1e5a;

$notification-orange: #f58c14;

$new-bg: rgba(34,42,46,0.9);

$training-training-bg: #32a0a0;
$training-article-bg: #004669;
$training-quiz-bg: #879baa;
$training-placeholder-bg: rgba(253,172,9,0.5);

$tile-color-sa: #8A9FB0;
$tile-color-ceo: #75899A;
$tile-color-smp: #9AA812;
$tile-color-ps: #9D0E4D;
$tile-color-sog: rgba(67,177,206,1);
$tile-color-msp: linear-gradient(to right, #50bebe 0%, #009999 50%, #0099b0 83%, #0099cb 100%);
$tile-color-sp: linear-gradient(to right, #50bebe 0%, #009999 50%, #0099b0 83%, #0099cb 100%);
$tile-color-mmt: #EFF3F6;
$tile-color-ec: #DFE6ED;
$tile-color-mip: #849718;
$tile-color-bap: #607626;
$tile-color-managing-task: #E6ECF2;
$tile-color-dr: #005F87;
$tile-color-academy: #ffb900;
$tile-color-hzp: #F29257;
$tile-color-hii: #006F6F;
$tile-color-his: #EC6602;
$tile-color-hsa: #8A9FB0;
$tile-color-hisg: linear-gradient(to right, rgba(236,118,2,1) 0%, rgba(235,94,7,1) 66.66%, rgba(226,106,2,1) 100%);


$tile-color-his-1: #C64F06;
$tile-color-his-2: #FD9A5D;

$color-siemens: #009999;
$color-healthineers: #EB7433;
$color-gamesa: #B0CA02;
$color-alstom: #1A3F94;

$color-siemens-gradient: linear-gradient(to right, #50BEBE 0%, #0099B0 66.66%, #0099CB 100%);
$color-healthineers-gradient: linear-gradient(to right, #EC7602 0%, #EB5E07 66.66%, #E26A02 100%);
$color-gamesa-gradient: linear-gradient(to right, #D2D741 0%, #9AA812 66.66%, #BEC328 100%);
$color-alstom-gradient: linear-gradient(to right, #3156AD 0%, #1A3F94 66.66%, #1D48A3 100%);

$tile-color-gradient-inverted: linear-gradient(to right, #0099cb 0%, #0099b0 50%, #009999 83%, #50bebe 100%);
$tile-color-to-1: #49BDBF;
$tile-color-to-2: #0098CC;

$sa-gradient: linear-gradient(to bottom, rgba(138,159,176,1) 0%, rgba(138,159,176,0) 100%);
$sa-gradient-light: linear-gradient(to bottom, rgba(138,159,176,0.75) 0%, rgba(138,159,176,0) 100%);
$sa-gradient-extra-light: linear-gradient(to bottom, rgba(138,159,176,0.5) 0%, rgba(138,159,176,0) 100%);
$ceo-gradient: linear-gradient(to bottom, rgba(117,137,154,1) 0%, rgba(117,137,154,0) 100%);
$ceo-gradient-light: linear-gradient(to bottom, rgba(117,137,154,0.75) 0%, rgba(117,137,154,0) 100%);
$ceo-gradient-extra-light: linear-gradient(to bottom, rgba(117,137,154,0.5) 0%, rgba(117,137,154,0) 100%);
$smp-gradient: linear-gradient(to bottom, rgba(154,168,18,1) 0%, rgba(154,168,18,0) 100%);
$smp-gradient-light: linear-gradient(to bottom, rgba(154,168,18,0.75) 0%, rgba(154,168,18,0) 100%);
$smp-gradient-extra-light: linear-gradient(to bottom, rgba(154,168,18,0.5) 0%, rgba(154,168,18,0) 100%);
$smp-gradient-extra-super-light: linear-gradient(to bottom, rgba(154,168,18,0.25) 0%, rgba(154,168,18,0) 100%);
$sps-gradient: linear-gradient(to bottom, rgba(157,14,77,1) 0%, rgba(157,14,77,0) 100%);
$sps-gradient-light: linear-gradient(to bottom, rgba(157,14,77,0.50) 0%, rgba(157,14,77,0) 100%);
$sps-gradient-extra-light: linear-gradient(to bottom, rgba(157,14,77,0.25) 0%, rgba(157,14,77,0) 100%);


$his-gradient: linear-gradient(to bottom, rgba(236,102,2,1) 0%, rgba(236,102,2,0) 100%);
$his-gradient-light: linear-gradient(to bottom, rgba(236,102,2,0.75) 0%, rgba(236,102,2,0) 100%);
$his-gradient-extra-light: linear-gradient(to bottom, rgba(236,102,2,0.5) 0%, rgba(236,102,2,0) 100%);


$tile-color-sa-hover: rgba(138,159,176,0.25);
$tile-color-ceo-hover: rgba(117,137,154,0.25);
$tile-color-smp-hover: rgba(154,168,18,0.25);
$tile-color-bap-hover: rgba(96,118,38,0.25);
$tile-color-mip-hover: rgba(132,151,24,0.25);
$tile-color-dr-hover: rgba(0,95,135,0.25);
$tile-color-sog-hover: rgba(67,177,206,0.25);
$tile-color-ps-hover: rgba(157,14,77,0.25);
$tile-color-his-hover: rgba(236,102,2,0.25);
$tile-color-hii-hover: rgba(0,111,111,0.25);
$tile-color-hsa-hover: rgba(138,159,176,0.25);

$tile-color-sa-opacity: rgba(138,159,176,0.75);
$tile-color-ceo-opacity: rgba(117,137,154,0.75);
$tile-color-smp-opacity: rgba(154,168,18,0.75);
$tile-color-bap-opacity: rgba(96,118,38,0.75);
$tile-color-mip-opacity: rgba(132,151,24,0.75);
$tile-color-dr-opacity: rgba(0,95,135,0.75);
$tile-color-sog-opacity: rgba(67,177,206,0.75);
$tile-color-ps-opacity: rgba(157,14,77,0.75);


$tile-color-his-program-opacity: rgba(236,102,2,0.8);
$tile-color-his-description-opacity: rgba(236,102,2,0.7);

$tile-color-hsa-program-opacity: rgba(138,159,176,0.8);
$tile-color-hsa-description-opacity: rgba(138,159,176,0.7);

$tile-color-sa-hover-hex: #758896;
$tile-color-ceo-hover-hex: #5F6F7D;
$tile-color-smp-hover-hex: #828F10;
$tile-color-bap-hover-hex: #4D5E1E;
$tile-color-mip-hover-hex: #6D7D14;
$tile-color-dr-hover-hex: #083D5E;
$tile-color-sps-hover-hex: #850C40;
$tile-color-sog-hover-hex: #3C9DB5;
$tile-color-hii-hover-hex: #006F6F;
$tile-color-his-hover-hex: #d85c00;

$v-distance-block: 30px;
$v-distance-block-l: 50px;
$v-distance-headline-xs: 15px;
$v-distance-headline-s: 25px;
$v-distance-headline-sm: 40px;
$v-distance-headline-m: 55px;
$v-distance-p: 20px;


// MAX WIDTH BASED ON MEDIA QUERIES
$max-width-s: 1100px;
$max-width-m: 1400px;
$max-width-l: 1550px;
$max-width-xl: 1800px;


// GRID
$grid-12: 100%;
$grid-11: 91.66666667%;
$grid-10: 83.33333333%;
$grid-9: 75%;
$grid-8: 66.66666667%;
$grid-7: 58.33333333%;
$grid-6: 50%;
$grid-5: 41.66666667%;
$grid-4: 33.33333333%;
$grid-3: 25%;
$grid-2: 16.66666667%;
$grid-1: 8.33333333%;

$grid-distance: 30px;
$grid-distance-half: calc($grid-distance / 2);


// HEADER
$header-height: 65px;


// SCALE FACTOR -> screen size > 1920
$desktop-l-factor: 1.285;


// default table padding
$table-padding: 15px;


// MENU & FAQ WIDTH
$menu-width: 320px;
$menu-width-big: $menu-width * $desktop-l-factor;


// CHART OFFSET
$chart-percentage-offset: 0.1;


// LIGHTBOX SIZES
$lightbox-small: 560px;
$lightbox-medium: 768px;
$lightbox-large: 1000px;
