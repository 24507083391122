// mixin clearfix
@mixin clearfix() {
  &:before,
  &:after {
    content: ' ';
    display: table;
  }
  &:after {
    clear: both;
  }
}

@mixin line-clamp($numLines : 1, $lineHeight: 1.412) {
  overflow: hidden;
  text-overflow: -o-ellipsis-lastline;
  text-overflow: ellipsis;
  display: block;
  /* autoprefixer: off */
  display: -webkit-box;
  -webkit-line-clamp: $numLines;
  -webkit-box-orient: vertical;
  // max-height: $numLines * $lineHeight + unquote('em');
}

// mixin media query
@mixin mq($type) {
  @if map-has-key($breakpoints, $type) {
    @media #{map-get($breakpoints, $type)} {
      @content;
    }
  } @else {
    @warn "Unknown breakpoint #{$type}!";
  }
}


// mixin flex center
@mixin flex-center() {
  display: flex;
  justify-content: center;
  align-items: center;
}

// mixin aspect ratio
@mixin aspect-ratio($width, $height) {
    position: relative;

    &:before {
        content: '';
        width: 100%;
        padding-top: ($height / $width) * 100%;
        display: block;
    }
}


// hide text
@mixin hide-text() {
    position: absolute;
    left: -9999px;
    top: auto;
    width: 1px;
    height: 1px;
    overflow: hidden;
}

// mixin aspect ratio
@mixin aspect-ratio-extra($width, $height, $additional-padding) {
    position: relative;

    &:before {
        content: '';
        width: 100%;
        padding-top: ($height / $width) * 100%;
        display: block;
    }

    @include mq(desktop-s) {
        &:before {
            padding-bottom: $additional-padding;
        }
    }
}

@mixin grid-wrapper() {

  width: 92.5%;
  margin: 0 auto;
  max-width: $max-width-s;
  transition: max-width 0.25s ease-in-out;
  display: block;
  position: relative;

  @include mq(desktop-m) {
    max-width: $max-width-m;
  }

  @include mq(desktop-l) {
    max-width: $max-width-l;
  }

  @include mq(desktop-xl) {
    max-width: $max-width-xl;
  }
}

@mixin vertical-padding($size) {

  @if $size == s {
    padding-top: 50px;
    padding-bottom: 50px;
  } @else if $size == m {
    padding-top: 80px;
    padding-bottom: 100px;
  }
}

@mixin vertical-margin-top($size) {

    @if $size == s {
        margin-top: 50px;
    } @else if $size == m {
        margin-top: 80px;
    }
}

@mixin vertical-margin-bottom($size) {

  @if $size == s {
    margin-bottom: 50px;
  } @else if $size == m {
    margin-bottom: 80px;
  } @else if $size == l {
      margin-bottom: 135px;
  }
}

@mixin transition($type, $time) {

  @if $time == s {
    transition: $type 0.15s ease-in-out;
  }

  @if $time == m {
    transition: $type 0.5s ease-in-out;
  }

  @if $time == l {
    transition: $type 1s ease-in-out;
  }

  @if $time == xl {
    transition: $type 2s ease-in-out;
  }
}

@mixin transparency-bg($color,$alpha) {
    background: rgba($color,$alpha);
}


@mixin list-item($type) {
    width: 100%;
    @include link-nav-primary;
    @include flex-center();
    @include transition(background, s);
    cursor: pointer;
    position: relative;
    padding: 0 20px;

    @if $type == new {
        background: $black;
    }

    @if $type == single {
        justify-content: flex-start;
    }

    a,
    label
    {
        @if $type == reg {
            @include copy-txt-chapter-title($font-color-grey);
        }

        @if $type == new {
            @include copy-txt-chapter-title($white);
        }

        @if $type == single {
            @include copy-txt-chapter-title($font-color-grey);
        }
    }

    a {
        width: 100%;
        display: block;

        @if $type == reg or $type == new {
            padding: 20px 50px 17px 0;
        }

        @if $type == single {
            padding: 20px 10px 17px 0;
        }

        @include transition(all, s);
    }

    input[type="checkbox"] + label {

        &:before {
            @if $type == reg {
                border: 1px solid $font-color-grey !important;
            }
            @if $type == new {
                border: 1px solid $white !important;
            }
        }

        &:after {
            width: 15px;
            height: 12px;
            @if $type == reg {
                @include icon(checkbox-check);
            }
            @if $type == new {
                @include icon(checkbox-check-white);
            }
        }
    }

    &:before {
        content: '';
        left: 20px;
        bottom: -1px;
        width: calc(100% - 40px);
        height: 1px;
        background: $font-color-grey-super-extra-light;
        position: absolute;
        pointer-events: none;
    }

    @if $type == reg or $type == new {
        &:after {
            content: '';
            top: 50%;
            right: 19px;
            width: 9px;
            height: 15px;

            @if $type == reg {
                @include icon(tile-arrow-dark);
                opacity: 0.2;
            }
            @if $type == new {
                @include icon(tile-arrow-light);
                opacity: 1;
            }

            background-size: 9px 15px;
            transform: translate(0, -8px);
            position: absolute;
            @include transition(opacity, s);
            pointer-events: none;
        }
    }

    &:hover {

        background: $link-color-hover;

        &:before {
            content: none;
        }

        &:after {
            opacity: 1;
            @include icon(tile-arrow-light);
            background-size: 9px 15px;
        }

        a,
        label {
            @include copy-txt-chapter-title($white);
        }

        input[type="checkbox"] + label {

            &:before {
                border: 1px solid $white !important;
            }

            &:after {
                width: 15px;
                height: 12px;
                @include icon(checkbox-check-white);
            }
        }
    }
}


@mixin tooltip-content($type, $posH, $posV) {

    width: 200px;
    text-align: left;
    background: $font-color;
    padding: 15px;
    position: absolute;
    @include copy-txt-small($white);
    z-index: 1000;
    cursor: default;
    display: none;

    @if $posV == bottom {

        &:before {
            content: '';
            left: 0;
            top: -15px;
            width: 100%;
            height: 15px;
            background: transparent;
            position: absolute;
        }

        &:after {
            content: '';
            left: 50%;
            top: -6px;
            margin: 0 0 0 -5px;
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 0 5px 6px 5px;
            border-color: transparent transparent $font-color transparent;
            position: absolute;
        }
    }

    @if $posV == top {

        margin-top: -30px;

        &:before {
            content: '';
            left: 0;
            bottom: -15px;
            width: 100%;
            height: 15px;
            background: transparent;
            position: absolute;
        }

        &:after {
            content: '';
            left: 50%;
            bottom: -6px;
            margin: 0 0 0 -5px;
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 6px 5px 0 5px;
            border-color: $font-color transparent transparent transparent;
            position: absolute;
        }
    }

    @if $type == asterix {
        top: 25px;
        left: -95px;
        right: auto;

        @if $posH == left {
            left: -12px;
            right: auto;

            &:after {
                left: 20px;
                right: auto;
            }
        }

        @if $posH == right {
            left: auto;
            right: -32px;

            &:after {
                left: auto;
                right: 20px;
            }
        }
    }

    @if $type == underline {
        left: 50%;
        top: 100%;
        transform: translate(-100px, 10px);

        @if $posH == left {
            left: 0;
            transform: translate(0, 10px);

            &:after {
                left: 20px;
                right: auto;
            }
        }

        @if $posH == right {
            right: 0;
            left: auto;
            transform: translate(0, 10px);

            &:after {
                right: 17px;
                left: auto;
            }
        }
    }

    @if $type == basic {
        left: 50%;
        top: 100%;

        @if $posV == bottom {
            transform: translate(-100px, 10px);
        }

        @if $posV == top {
            transform: translate(-100px, -100%);
        }

        @if $posH == left {
            left: 0;

            @if $posV == bottom {
                transform: translate(-13px, 10px);
            }

            @if $posV == top {
                transform: translate(-13px, -100%);
            }

            &:after {
                left: 20px;
                right: auto;
            }
        }

        @if $posH == right {
            right: 0;
            left: auto;

            @if $posV == bottom {
                transform: translate(13px, 10px);
            }

            @if $posV == top {
                transform: translate(13px, -100%);
            }

            &:after {
                right: 17px;
                left: auto;
            }
        }
    }

    .m-detail {
        margin: 0 0 20px 0;
        max-height: 280px;
        overflow-y: auto;

        &:last-of-type {
            margin: 0;
        }
    }

    .m-headline-wrapper {
        @include flex-center();
        align-items: flex-start;
        justify-content: flex-start;
        margin: 0 0 5px 0;

        > .m-headline {
            width: calc(100% - 28px);
            margin-left: 8px;
        }

        .m-position {
            width: 20px;
            height: 20px;
            border-radius: 10px;
            border: 2px solid $white;
            background: none;
            @include nav-txt-circle-txt($white);
            @include flex-center();
        }
    }

    .m-headline {
        @include copy-txt-small-bold($white);
    }

    ul {
        margin: 0 0 10px 0;

        &:last-of-type {
            margin: 0;
        }

        li {
            @include list-item-bullet-small($white);
        }
    }
}


@mixin input-inactive() {
    background: #dfe6ed;
    border: 1px solid #cdd9e1;
    color: #879baa;

    ::-webkit-input-placeholder {
        color: #879baa;
    }
    ::-moz-placeholder {
        color: #879baa;
    }
    :-ms-input-placeholder {
        color: #879baa;
    }
    :-moz-placeholder {
        color: #879baa;
    }
}

@mixin remove-default-hover-table() {

    &--sa {
        > .m-content {
            &--detail {
                &:hover {
                    background: $tile-color-sa;
                }
            }
        }
    }

    &--ceo {
        > .m-content {
            &--detail {
                &:hover {
                    background: $tile-color-ceo;
                }
            }
        }
    }
}


@mixin report-list-entry() {

    @include flex-center();
    justify-content: flex-start;
    position: relative;

    &:after {
        content: '';
        top: 50%;
        right: 25px;
        opacity: 0.2;
        width: 8px !important;
        height: 13px !important;
        @include icon(tile-arrow-dark);
        background-size: 8px 13px;
        transform: translate(0, -6px);
        position: absolute;
        @include transition(opacity, s);
        visibility: hidden;
    }

    &[ng-click] {
        cursor: pointer;

        &:after {
            visibility: visible;
        }
    }

    > .m-desc {
        @include copy-txt-chapter-title($font-color);
        width: 35%;
        padding-left: 10px;
        transition: color 0.15s ease-in-out;
    }

    > .m-name {
        @include copy-txt-chapter-title($font-color);
        width: 40%;
        transition: color 0.15s ease-in-out;
    }

    > .m-loader {
        @include flex-center();
        justify-content: flex-start;
        width: 25%;
        transition: color 0.15s ease-in-out;

        .m-circle-loader {
            margin: -2px 10px 0 0;

            .m-content {
                width: 22px;
                height: 22px;
                background-color: #C2D0DA;
                border-radius: 22px;
                transform: rotate(180deg);
                position: relative;

                &:after {
                    content: '';
                    position: absolute;
                    left: 3px;
                    top: 3px;
                    width: 16px;
                    height: 16px;
                    border-radius: 50%;
                    background-color: $white;
                    transition: background-color 0.15s ease-in-out;
                }

                .m-loader-wrapper {
                    left: 0;
                    width: 50%;
                    height: 100%;
                    position: absolute;
                    overflow: hidden;

                    .m-loader {
                        position: absolute;
                        left: 100%;
                        top: 0;
                        width: 100%;
                        height: 100%;
                        background-color: $tile-color-ps;
                        border-radius: 22px;
                    }

                    &--left {
                        left: 0;

                        .m-loader {
                            border-top-left-radius: 0;
                            border-bottom-left-radius: 0;
                            transform-origin: 0 50% 0;
                            transform: rotate(80deg)
                        }
                    }

                    &--right {
                        left: 50%;

                        .m-loader {
                            left: -100%;
                            border-bottom-right-radius: 0;
                            border-top-right-radius: 0;
                            transform-origin: 100% 50% 0;
                            transform: rotate(0deg);
                            display: none;
                        }
                    }
                }
            }
        }

        .m-percentage {
            @include copy-txt-chapter-title($font-color);
            display: inline-block;
            float: right;
            transition: color 0.15s ease-in-out;
        }
    }


    &:hover {

        &:after {
            //opacity: 1;
        }
    }
}


@mixin list-hover {

    &:hover {
        background: $link-color-hover;

        > .m-entry {

            &:after {
                @include icon(tile-arrow-light);
                opacity: 1;
            }
        }

        .m-desc,
        .m-name,
        .m-loader {
            @include copy-txt-chapter-title($white);

            .m-percentage {
                @include copy-txt-chapter-title($white);
            }

            .m-circle-loader {

                > .m-content {

                    &:after {
                        background: $link-color-hover;
                    }
                }
            }
        }
    }
}


@mixin profile() {
    @include flex-center();
    justify-content: flex-start;
    flex-direction: row;
    margin: 0 0 15px 0;
    position: relative;

    > .m-image {
        width: 50px;
        height: 50px;
        border-radius: 25px;
        border: 1px solid $font-color-grey-extra-light;
        margin: 0 10px 0 0;
        cursor: pointer;
        position: relative;

        &:before {
            content: '';
            left: 0;
            top: 0;
            width: 48px;
            height: 48px;
            border-radius: 24px;
            background: $blue-dark;
            position: absolute;
            opacity: 0;
            transition: opacity 0.15s ease-in-out;
        }

        &:after {
            content: '';
            left: 50%;
            top: 50%;
            @include icon(edit);
            width: 38px*0.3;
            height: 57px*0.3;
            transform: translate(-50%,-50%);
            position: absolute;
            opacity: 0;
            transition: opacity 0.15s ease-in-out;
        }

        &:hover {

            &:before {
                opacity: 0.8;
            }

            &:after {
                opacity: 1;
            }
        }
    }

    > .m-info {
        .m-name,
        .m-user-id {
            @include copy-txt($grey);
        }
    }
}

@mixin notification() {

    right: 0;
    top: 0;
    min-width: 20px;
    height: 20px;
    border-radius: 10px;
    border: 2px solid $white;
    background: $notification-orange;
    position: absolute;
    @include nav-txt-circle-txt($white);
    @include flex-center();

    // detect IE
    @media screen and (-webkit-min-device-pixel-ratio: 0) {
        //padding-top: 4px;
    }
}

@mixin new-tag() {
    right: 0;
    top: 0;
    padding: 7px 21px;
    position: absolute;
    background: $new-bg;
    @include headline-txt($white);
    z-index: 1;
}

@mixin print-clean() {
    color: $font-color !important;
    margin: 0 !important;
    padding: 0 !important;
    width: 100% !important;
    max-width: 100% !important;
    height: auto !important;
    min-height: 0 !important;
    max-height: 100% !important;
}

@mixin print-section() {
    border-bottom: 2px solid $tile-msp-border !important;
    margin-bottom: 50px !important;
    padding-bottom: 50px !important;
}

@mixin print-bottom-distance() {
    margin-bottom: 50px !important;
}
